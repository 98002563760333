import React, { ReactElement, useState } from 'react'
import styled from 'styled-components/macro'

import TabTitle from './TabTitle'

const TabGroup = styled.div`
  height: 30px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
`
type TabsProps = {
  children: ReactElement[]
}

const Tabs = ({ children }: TabsProps) => {
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <div>
      <TabGroup>
        {children.map((item, index) => (
          <TabTitle
            key={index}
            title={item.props.title}
            index={index}
            setSelectedTab={setSelectedTab}
            selected={selectedTab === index}
          />
        ))}
      </TabGroup>
      {children[selectedTab]}
    </div>
  )
}

export default Tabs
