import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { ReponseTransactionStatus } from './type'

//RTK Query will automatically generate hooks for each endpoint query
interface TransactionParams {
  typeTransaction: string
  pageNumber: number
  url?: string
}

export const transactionApi = createApi({
  reducerPath: 'transactionStatusApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '',
    prepareHeaders: (headers, { getState }) => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`)
      }
      return headers
    },
  }),
  endpoints: (build) => ({
    getTransactionStatus: build.query<ReponseTransactionStatus, TransactionParams>({
      async queryFn(args, _api, _extraOptions, fetch) {
        try {
          const result = await fetch(
            `${args.url}/transaction?type=${args.typeTransaction}&page=${
              args.pageNumber || 1
            }&numberOfRecordsPerPage=5&status=all`
          )
          return result as any
        } catch (error) {
          return { error: error as any }
        }
      },
      extraOptions: {
        maxRetries: 0,
      },
    }),
  }),
})

export const { useGetTransactionStatusQuery } = transactionApi
