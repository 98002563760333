import { createReducer } from '@reduxjs/toolkit'
import { QuoteResponseType } from 'hooks/swap/useLiquidityProvider'

import { setLiquidityProvider } from './actions'
const currentTimestamp = () => new Date().getTime()

export interface LiquidityProvider {
  liquidityProvider: QuoteResponseType | undefined
  timestamp: number
}

export const initialState: LiquidityProvider = {
  liquidityProvider: undefined,
  timestamp: currentTimestamp(),
}

export default createReducer(initialState, (builder) =>
  builder.addCase(setLiquidityProvider, (state, action) => {
    state.liquidityProvider = action.payload.liquidityProvider
    state.timestamp = currentTimestamp()
  })
)
