import { createReducer } from '@reduxjs/toolkit'
import { parsedQueryString } from 'hooks/environment/useParsedQueryString'

import { Field, replaceSwapState, selectCurrency, setRecipient, switchCurrencies, typeInput } from './actions'
import { queryParametersToSwapState } from './hooks'

export interface SwapState {
  readonly independentField: Field
  readonly typedValue: string
  readonly [Field.INPUT]: {
    readonly currencyId: string | undefined | null
    readonly symbol?: string | undefined
    readonly tokenName?: string | undefined
    readonly isNative?: boolean | undefined
    readonly tokenAddress?: string | undefined
    readonly chainId?: number | undefined
  }
  readonly [Field.OUTPUT]: {
    readonly currencyId: string | undefined | null
    readonly symbol?: string | undefined
    readonly tokenName?: string | undefined
    readonly isNative?: boolean | undefined
    readonly tokenAddress?: string | undefined
    readonly chainId?: number | undefined
  }
  // the typed recipient address or ENS name, or null if swap should go to sender
  readonly recipient: string | null
}

const initialState: SwapState = queryParametersToSwapState(parsedQueryString())

export default createReducer<SwapState>(initialState, (builder) =>
  builder
    .addCase(
      replaceSwapState,
      (state, { payload: { typedValue, recipient, field, inputCurrency, outputCurrency } }) => {
        return {
          [Field.INPUT]: inputCurrency ?? null,
          [Field.OUTPUT]: outputCurrency ?? null,
          independentField: field,
          typedValue,
          recipient,
        }
      }
    )
    .addCase(
      selectCurrency,
      (state, { payload: { currencyId, field, symbol, tokenName, isNative, tokenAddress, chainId } }) => {
        const otherField = field === Field.INPUT ? Field.OUTPUT : Field.INPUT
        //save to backend when dispatched
        const data = {
          tokenAddress,
        }
        const accessToken = localStorage.getItem('accessToken')
        fetch(`${process.env.REACT_APP_BACKEND_URL}/token/saveSearchedHistory`, {
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          method: 'POST',
        })
        if (currencyId === state[otherField].currencyId) {
          // the case where we have to swap the order
          return {
            ...state,
            independentField: state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
            [field]: { currencyId },
            [otherField]: { currencyId: state[field].currencyId },
          }
        } else {
          // the normal case
          return {
            ...state,
            [field]: { currencyId, symbol, tokenName, isNative, tokenAddress, chainId },
          }
        }
      }
    )
    .addCase(switchCurrencies, (state) => {
      return {
        ...state,
        independentField: state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
        [Field.INPUT]: {
          currencyId: state[Field.OUTPUT].currencyId,
          symbol: state[Field.OUTPUT].symbol,
          tokenName: state[Field.OUTPUT].tokenName,
          isNative: state[Field.OUTPUT].isNative,
          tokenAddress: state[Field.OUTPUT].tokenAddress,
          chainId: state[Field.OUTPUT].chainId,
        },
        [Field.OUTPUT]: {
          currencyId: state[Field.INPUT].currencyId,
          symbol: state[Field.INPUT].symbol,
          tokenName: state[Field.INPUT].tokenName,
          isNative: state[Field.INPUT].isNative,
          tokenAddress: state[Field.INPUT].tokenAddress,
          chainId: state[Field.INPUT].chainId,
        },
      }
    })
    .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
      return {
        ...state,
        independentField: field,
        typedValue,
      }
    })
    .addCase(setRecipient, (state, { payload: { recipient } }) => {
      state.recipient = recipient
    })
)
