import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const apiEndpoint = 'https://api.etherscan.io/api'
const apiKey = process.env.REACT_APP_ETHERSCAN_API_KEY
export const gasPriceApi = createApi({
  reducerPath: 'gasPriceApi',
  baseQuery: fetchBaseQuery({ baseUrl: apiEndpoint }),
  endpoints: (builder) => ({
    getGasPrice: builder.query({
      query: () => {
        return `?module=gastracker&action=gasoracle&apikey=${apiKey}`
      },
      extraOptions: {
        maxRetries: 0,
      },
    }),
  }),
})

//RTK Query will automatically generate hooks for each endpoint query
export const { useGetGasPriceQuery } = gasPriceApi
