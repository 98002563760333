import { BigintIsh, CurrencyAmount, Token, TradeType } from '@uniswap/sdk-core'
import { AlphaRouter, AlphaRouterConfig, AlphaRouterParams } from '@uniswap/smart-order-router'
import { SupportedNetworkId } from 'constants/networks'
import JSBI from 'jsbi'
import { GetQuoteResult } from 'state/routing/types'
import { transformSwapRouteToGetQuoteResult } from 'utils/transformSwapRouteToGetQuoteResult'

async function getQuote(
  {
    type,
    networkId,
    tokenIn,
    tokenOut,
    amount: amountRaw,
  }: {
    type: 'exactIn' | 'exactOut'
    networkId: SupportedNetworkId
    tokenIn: { address: string; networkId: number; decimals: number; symbol?: string }
    tokenOut: { address: string; networkId: number; decimals: number; symbol?: string }
    amount: BigintIsh
  },
  routerParams: AlphaRouterParams,
  routerConfig: Partial<AlphaRouterConfig>
): Promise<{ data: GetQuoteResult; error?: unknown }> {
  const router = new AlphaRouter(routerParams)
  const currencyIn = new Token(tokenIn.networkId, tokenIn.address, tokenIn.decimals, tokenIn.symbol)
  const currencyOut = new Token(tokenOut.networkId, tokenOut.address, tokenOut.decimals, tokenOut.symbol)

  const baseCurrency = type === 'exactIn' ? currencyIn : currencyOut
  const quoteCurrency = type === 'exactIn' ? currencyOut : currencyIn
  const amount = CurrencyAmount.fromRawAmount(baseCurrency, JSBI.BigInt(amountRaw))

  const swapRoute = await router.route(
    amount,
    quoteCurrency,
    type === 'exactIn' ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT,
    /*swapConfig=*/ undefined,
    routerConfig
  )

  if (!swapRoute) throw new Error('Failed to generate client side quote')

  return { data: transformSwapRouteToGetQuoteResult(type, amount, swapRoute) }
}

interface QuoteArguments {
  tokenInAddress: string
  tokenInNetworkId: SupportedNetworkId
  tokenInDecimals: number
  tokenInSymbol?: string
  tokenOutAddress: string
  tokenOutNetworkId: SupportedNetworkId
  tokenOutDecimals: number
  tokenOutSymbol?: string
  amount: string
  type: 'exactIn' | 'exactOut'
}

export async function getClientSideQuote(
  {
    tokenInAddress,
    tokenInNetworkId,
    tokenInDecimals,
    tokenInSymbol,
    tokenOutAddress,
    tokenOutNetworkId,
    tokenOutDecimals,
    tokenOutSymbol,
    amount,
    type,
  }: QuoteArguments,
  routerParams: AlphaRouterParams,
  routerConfig: Partial<AlphaRouterConfig>
) {
  return getQuote(
    {
      type,
      networkId: tokenInNetworkId,
      tokenIn: {
        address: tokenInAddress,
        networkId: tokenInNetworkId,
        decimals: tokenInDecimals,
        symbol: tokenInSymbol,
      },
      tokenOut: {
        address: tokenOutAddress,
        networkId: tokenOutNetworkId,
        decimals: tokenOutDecimals,
        symbol: tokenOutSymbol,
      },
      amount,
    },
    routerParams,
    routerConfig
  )
}
