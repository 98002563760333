import ms from 'ms.macro'
import { useContext, useMemo, useState } from 'react'
import { HashLoader } from 'react-spinners'
import { useGetTransactionStatusQuery } from 'state/transactionStatus/slice'
import styled, { ThemeContext } from 'styled-components/macro'

import { baseUrl } from '../ListAllTransaction'
import Pagination from '../Pagination'
import TransStatusSwap from './TransStatusSwap'
export const ListTransaction = styled.div`
  display: flex;
  padding: 10px 20px 20px 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-right: 20px;
`
export const WrapperPagination = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  bottom: 0px;
`

export const TransactionDetails = styled.div`
  width: 580px;
  padding: 23px 20px 25px 20px;
`
export const ContentTransactionDetails = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`
export const ColumnLeft = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const ColumnRight = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const InfoTransaction = styled.p`
  color: ${({ theme }) => theme.text2};
  font-size: 12px;
  font-weight: 400;
`
export const InfoValue = styled.p`
  color: ${({ theme }) => theme.text6};
  font-size: 12px;
  font-weight: 400;
`
export const WrapTransaction = styled.div`
  height: 550px;
  width: 587px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 24px;
  padding-bottom: 5px;
`
export const WrapperSwap = styled.div<{ open?: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ open }) => (open ? '261px' : '84px')};
  justify-content: space-between;
  align-items: center;
  width: 587px;
  border-radius: 12px;
  border: ${({ open }) => (open ? '1px solid rgba(205, 208, 215, 0.4)' : 'none')};
  box-shadow: 10px 24px 54px rgba(0, 0, 0, 0.06), 0px 0px 5px rgba(7, 44, 33, 0.05);
  background: ${({ theme }) => theme.bg0};
  transition: 0.5s;
  overflow: hidden;
`
export const TitleTradingDetail = styled.p`
  color: ${({ theme }) => theme.green4};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: -5px;
`
export const ValueAmountSell = styled.div`
  color: ${({ theme }) => theme.red4};
`
export const ValueAmountBuy = styled.div`
  color: ${({ theme }) => theme.green5};
`
export const ValueTransaction = styled.p`
  font-size: 16px;
  font-weight: 400;
  display: flex;
  width: 287px;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.green5};
  margin-top: -17px;
`
export const Divide = styled.div`
  background-color: ${({ theme }) => theme.border1};
  height: 1px;
`
export const WrapIconLoading = styled.div`
  margin-top: 35%;
  margin-left: 50%;
`
export const WrapNotify = styled.div`
  margin-top: 35%;
  margin-left: 36%;
  font-size: 16px;
  font-weight: 600;
`

const ListSwapTransaction = () => {
  const theme = useContext(ThemeContext)
  const [clickedIndex, setClickedIndex] = useState<any>({})
  const [page, setPage] = useState(1)
  const {
    data: valueTransaction,
    isLoading,
    error,
  } = useGetTransactionStatusQuery(
    { typeTransaction: 'swap', pageNumber: page, url: baseUrl },
    {
      pollingInterval: ms`30s`,
      refetchOnMountOrArgChange: true,
    }
  )
  const transactions = useMemo(() => valueTransaction, [valueTransaction])
  const handlePrevPage = (prevPage: number) => {
    setPage((prevPage) => prevPage - 1)
  }

  const handleNextPage = (nextPage: number) => {
    setPage((nextPage) => nextPage + 1)
  }
  const handleClick = (index: number, transactionHash: string) => () => {
    setClickedIndex((state: any) => ({
      ...state,
      [index]: !state[index],
    }))
  }

  return (
    <>
      <WrapTransaction>
        {isLoading || error ? (
          <WrapIconLoading>
            <HashLoader size={35} color={theme.blue1} />
          </WrapIconLoading>
        ) : !valueTransaction?.result.data.length ? (
          <WrapNotify>No transaction history!</WrapNotify>
        ) : (
          <>
            <TransStatusSwap
              transactions={transactions}
              handleOnClick={handleClick}
              clickedIndex={clickedIndex}
              setPage={setPage}
            />{' '}
          </>
        )}
      </WrapTransaction>
      {valueTransaction?.result.totalPage !== 0 && (
        <WrapperPagination>
          <div></div>
          <Pagination
            totalPages={valueTransaction?.result.totalPage}
            currentPage={page}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
          />
        </WrapperPagination>
      )}
    </>
  )
}
export default ListSwapTransaction
