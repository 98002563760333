import { Web3Provider } from '@ethersproject/providers'
import UNISWAP_LOGO_URL from 'assets/svg/logo.svg'
import { ALL_SUPPORTED_NETWORK_IDS, SupportedNetworkId } from 'constants/networks'
import { INFURA_NETWORK_URLS } from 'constants/networks'
import getLibrary from 'utils/getLibrary'
import { InjectedConnector } from 'web3-react-injected-connector'
import { PortisConnector } from 'web3-react-portis-connector'
import { WalletConnectConnector } from 'web3-react-walletconnect-connector'
import { WalletLinkConnector } from 'web3-react-walletlink-connector'

import { NetworkConnector } from './NetworkConnector'

const PORTIS_ID = process.env.REACT_APP_PORTIS_ID

export const network = new NetworkConnector({
  urls: INFURA_NETWORK_URLS,
  defaultNetworkId: 1,
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? getLibrary(network.provider))
}

export const injected = new InjectedConnector({
  supportedChainIds: ALL_SUPPORTED_NETWORK_IDS,
})

export const walletconnect = new WalletConnectConnector({
  supportedChainIds: ALL_SUPPORTED_NETWORK_IDS,
  rpc: INFURA_NETWORK_URLS,
  qrcode: true,
})

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1],
})

export const walletlink = new WalletLinkConnector({
  url: INFURA_NETWORK_URLS[SupportedNetworkId.MAINNET],
  appName: 'Uniswap',
  appLogoUrl: UNISWAP_LOGO_URL,
  supportedChainIds: ALL_SUPPORTED_NETWORK_IDS,
})
