import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { EventFilter, filterToKey, Log } from './utils'

export interface LogsState {
  [networkId: number]: {
    [filterKey: string]: {
      listeners: number
      fetchingBlockNumber?: number
      results?:
        | {
            blockNumber: number
            logs: Log[]
            error?: undefined
          }
        | {
            blockNumber: number
            logs?: undefined
            error: true
          }
    }
  }
}

const slice = createSlice({
  name: 'logs',
  initialState: {} as LogsState,
  reducers: {
    addListener(state, { payload: { networkId, filter } }: PayloadAction<{ networkId: number; filter: EventFilter }>) {
      if (!state[networkId]) state[networkId] = {}
      const key = filterToKey(filter)
      if (!state[networkId][key])
        state[networkId][key] = {
          listeners: 1,
        }
      else state[networkId][key].listeners++
    },
    fetchingLogs(
      state,
      {
        payload: { networkId, filters, blockNumber },
      }: PayloadAction<{ networkId: number; filters: EventFilter[]; blockNumber: number }>
    ) {
      if (!state[networkId]) return
      for (const filter of filters) {
        const key = filterToKey(filter)
        if (!state[networkId][key]) continue
        state[networkId][key].fetchingBlockNumber = blockNumber
      }
    },
    fetchedLogs(
      state,
      {
        payload: { networkId, filter, results },
      }: PayloadAction<{ networkId: number; filter: EventFilter; results: { blockNumber: number; logs: Log[] } }>
    ) {
      if (!state[networkId]) return
      const key = filterToKey(filter)
      const fetchState = state[networkId][key]
      if (!fetchState || (fetchState.results && fetchState.results.blockNumber > results.blockNumber)) return
      fetchState.results = results
    },
    fetchedLogsError(
      state,
      {
        payload: { networkId, filter, blockNumber },
      }: PayloadAction<{ networkId: number; blockNumber: number; filter: EventFilter }>
    ) {
      if (!state[networkId]) return
      const key = filterToKey(filter)
      const fetchState = state[networkId][key]
      if (!fetchState || (fetchState.results && fetchState.results.blockNumber > blockNumber)) return
      fetchState.results = {
        blockNumber,
        error: true,
      }
    },
    removeListener(
      state,
      { payload: { networkId, filter } }: PayloadAction<{ networkId: number; filter: EventFilter }>
    ) {
      if (!state[networkId]) return
      const key = filterToKey(filter)
      if (!state[networkId][key]) return
      state[networkId][key].listeners--
    },
  },
})

export default slice.reducer
export const { addListener, removeListener, fetchedLogs, fetchedLogsError, fetchingLogs } = slice.actions
