import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import axios from 'axios'
import ms from 'ms.macro'

import { CHAIN_LIST } from '../../constants/chainlist'

export type TItemToken = {
  uuid: string
  symbol: string
  name: string
  mainnet_address: string
  testnet_address: string | null
  decimals: number
  logo_uri: string
  eip_2612: string | null
  displayed_symbol: string | null
  synth: string | null
  is_supported: boolean
  rank?: string
  lastPrice: number
  priceChange24hPercentage: number
  priceChange24h: number
  marketCap: number
}

export type TItemTokenObject = {
  uuid: string
  chain_id: number
  name: string
  symbol: string
  is_supported: boolean
  is_mainnet: boolean
  tokens: TItemToken[]
}

export interface IResponseToken {
  data: TItemTokenObject[]
  meta: any
}

function getKeyByValue(object: any, value: number) {
  return Object.keys(object).find((key) => object[key] === value)
}

function platformToken(networkId: number) {
  if (networkId) {
    return getKeyByValue(CHAIN_LIST, networkId)
  }
  return 'ethereum'
}

export const tokenWhatsHotApi = createApi({
  reducerPath: 'tokenWhatsHotApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_BACKEND_URL}` }),
  endpoints: (builder) => ({
    getTokenWhatsHot: builder.query({
      async queryFn(args, _api, _extraOptions, fetch) {
        try {
          const result = await fetch(`token/top`)
          const data: TItemTokenObject[] | any = result.data
          const tokensInfo = await Promise.all(
            data.tokens.map(
              async (item: any) =>
                await axios.get(
                  `https://api.coingecko.com/api/v3/coins/${platformToken(
                    item.network.chain_id
                  )}/contract/${item.mainnet_address.toLowerCase()}`
                )
            )
          )

          const dataTokens = data.tokens.map((token: TItemToken, index: number) => ({
            ...token,
            rank: tokensInfo[index].data.market_cap_rank,
            lastPrice: parseFloat(tokensInfo[index].data.market_data.current_price['usd']).toFixed(2),
            priceChange24hPercentage: parseFloat(
              tokensInfo[index].data.market_data.price_change_percentage_24h_in_currency['usd']
            ).toFixed(2),
            priceChange24h: parseFloat(tokensInfo[index].data.market_data.price_change_24h_in_currency['usd']).toFixed(
              2
            ),
            marketCap: parseFloat(tokensInfo[index].data.market_data.market_cap['usd']).toFixed(2),
          }))

          return { data: dataTokens as TItemTokenObject[] }
        } catch (e) {
          // TODO: fall back to client-side quoter when auto router fails.
          // deprecate 'legacy' v2/v3 routers first.
          return { error: e as FetchBaseQueryError }
        }
      },
      keepUnusedDataFor: ms`10s`,
      extraOptions: {
        maxRetries: 0,
      },
    }),
  }),
})

export const { useGetTokenWhatsHotQuery } = tokenWhatsHotApi

export const tokenSupportedApi = createApi({
  reducerPath: 'tokenSupportedApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_BACKEND_URL}` }),
  endpoints: (builder) => ({
    getTokenSupported: builder.query({
      async queryFn(args, _api, _extraOptions, fetch) {
        try {
          const result = await fetch(`token/supported`)
          const data: TItemTokenObject[] | any = result.data
          let dataNetwork

          if (data && data.length) {
            dataNetwork = data.find((item: TItemTokenObject) => item.chain_id === args)
          }

          return { data: dataNetwork as TItemTokenObject }
        } catch (e) {
          // TODO: fall back to client-side quoter when auto router fails.
          // deprecate 'legacy' v2/v3 routers first.
          return { error: e as FetchBaseQueryError }
        }
      },
      keepUnusedDataFor: ms`10s`,
      extraOptions: {
        maxRetries: 0,
      },
    }),
  }),
})

export const { useGetTokenSupportedQuery } = tokenSupportedApi

export const tokenTopCoinsApi = createApi({
  reducerPath: 'tokenTopCoinsApi',
  baseQuery: fetchBaseQuery({ baseUrl: `https://api.coingecko.com/api/v3/` }),
  endpoints: (builder) => ({
    getTokenTopCoins: builder.query({
      async queryFn(args, _api, _extraOptions, fetch) {
        try {
          const result = await fetch(`search/trending`)
          const data: TItemTokenObject[] | any = result.data

          const tokensInfo = await Promise.all(
            data.coins.map(
              async (token: any) => await axios.get(`https://api.coingecko.com/api/v3/coins/${token.item.id}`)
            )
          )

          const dataTokens = data.coins.map((coin: any, index: number) => ({
            ...coin.item,
            mainnet_address:
              tokensInfo[index].data.mainnet_address || tokensInfo[index].data.contract_address || 'none',
            rank: tokensInfo[index].data.market_cap_rank,
            lastPrice: parseFloat(tokensInfo[index].data.market_data.current_price['usd']).toFixed(2),
            priceChange24hPercentage: parseFloat(
              tokensInfo[index].data.market_data.price_change_percentage_24h_in_currency['usd']
            ).toFixed(2),
            priceChange24h: parseFloat(tokensInfo[index].data.market_data.price_change_24h_in_currency['usd']).toFixed(
              2
            ),
            marketCap: parseFloat(tokensInfo[index].data.market_data.market_cap['usd']).toFixed(2),
          }))

          return { data: dataTokens as TItemTokenObject[] }
        } catch (e) {
          // TODO: fall back to client-side quoter when auto router fails.
          // deprecate 'legacy' v2/v3 routers first.
          return { error: e as FetchBaseQueryError }
        }
      },
      keepUnusedDataFor: ms`10s`,
      extraOptions: {
        maxRetries: 0,
      },
    }),
  }),
})

export const { useGetTokenTopCoinsQuery } = tokenTopCoinsApi
