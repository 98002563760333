import { createAction, createReducer } from '@reduxjs/toolkit'

export interface PriceMultifull {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        [key: string]: string
      }
    }
  }
}
export const setChartPriceMultifull = createAction<string>('setChartCurrentPrice')
const initialState: string = '' as any
export default createReducer(initialState, (builder) => {
  builder.addCase(setChartPriceMultifull, (state, action) => {
    return (state = action.payload)
  })
  // You can chain calls, or have separate `builder.addCase()` lines each time
})
