import { createAction } from '@reduxjs/toolkit'
import { TradeType } from '@uniswap/sdk-core'

export interface SerializableTransactionReceipt {
  to: string
  from: string
  contractAddress: string
  transactionIndex: number
  blockHash: string
  transactionHash: string
  blockNumber: number
  status?: number
}

/**
 * Be careful adding to this enum, always assign a unique value (typescript will not prevent duplicate values).
 * These values is persisted in state and if you change the value it will cause errors
 */
export enum TransactionType {
  APPROVAL = 0,
  SWAP = 1,
  WRAP = 7,
  BRIDGE = 9,
}

export enum CheckTransactionType {
  TRADE = 'TRADE',
  SWAP = 'SWAPPING',
  BRIDGE = 'BRIDGING',
  CROSS_CHAIN = 'CROSS_CHAIN',
}
export enum BridgeStatus {
  TRANSFER_UNKNOWN = 'TRANSFER_UNKNOWN',
  TRANSFER_SUBMITTING = 'TRANSFER_SUBMITTING',
  TRANSFER_FAILED = 'TRANSFER_FAILED',
  TRANSFER_WAITING_FOR_SGN_CONFIRMATION = 'TRANSFER_WAITING_FOR_SGN_CONFIRMATION',
  TRANSFER_WAITING_FOR_FUND_RELEASE = 'TRANSFER_WAITING_FOR_FUND_RELEASE',
  TRANSFER_COMPLETED = 'TRANSFER_COMPLETED',
  TRANSFER_TO_BE_REFUNDED = 'TRANSFER_TO_BE_REFUNDED',
  TRANSFER_REQUESTING_REFUND = 'TRANSFER_REQUESTING_REFUND',
  TRANSFER_REFUND_TO_BE_CONFIRMED = 'TRANSFER_REFUND_TO_BE_CONFIRMED',
  TRANSFER_CONFIRMING_YOUR_REFUND = 'TRANSFER_CONFIRMING_YOUR_REFUND',
  TRANSFER_REFUNDED = 'TRANSFER_REFUNDED',
}

export enum TransactionStatus {
  SUBMIT = 'submit',
  PENDING = 'pending',
  CANCEL = 'cancel',
  PASSED = 'passed',
  FAILED = 'failed',
  SUCCESS = 'success',
}
export interface BaseTransactionInfo {
  type: TransactionType
}

export interface ApproveTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.APPROVAL
  tokenAddress: string
  spender: string
}

interface BaseSwapTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.SWAP
  tradeType: TradeType
  inputCurrencyId: string
  outputCurrencyId: string
}
export interface BridgeFundTransfer extends BaseTransactionInfo {
  type: TransactionType.BRIDGE
  tokenAddress: string
  inputAmount: string
}

export interface ExactInputSwapTransactionInfo extends BaseSwapTransactionInfo {
  tradeType: TradeType.EXACT_INPUT
  inputCurrencyAmountRaw: string
  expectedOutputCurrencyAmountRaw: string
  minimumOutputCurrencyAmountRaw: string
}
export interface ExactOutputSwapTransactionInfo extends BaseSwapTransactionInfo {
  tradeType: TradeType.EXACT_OUTPUT
  outputCurrencyAmountRaw: string
  expectedInputCurrencyAmountRaw: string
  maximumInputCurrencyAmountRaw: string
}

export interface WrapTransactionInfo {
  type: TransactionType.WRAP
  unwrapped: boolean
  currencyAmountRaw: string
  networkId?: number
}

export type TransactionInfo =
  | ApproveTransactionInfo
  | ExactOutputSwapTransactionInfo
  | ExactInputSwapTransactionInfo
  | WrapTransactionInfo
  | BridgeFundTransfer

export const addTransaction = createAction<{
  networkId: number
  hash: string
  from: string
  info: TransactionInfo
}>('transactions/addTransaction')
export const clearAllTransactions = createAction<{ networkId: number }>('transactions/clearAllTransactions')
export const finalizeTransaction = createAction<{
  networkId: number
  hash: string
  receipt: SerializableTransactionReceipt
}>('transactions/finalizeTransaction')
export const checkedTransaction = createAction<{
  networkId: number
  hash: string
  blockNumber: number
}>('transactions/checkedTransaction')
