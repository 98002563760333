// import { useFetchTransactionStatus } from 'hooks/blockchain/useFetchTransactionStatus'
import ms from 'ms.macro'
import { useContext, useState } from 'react'
import { HashLoader } from 'react-spinners'
import { useGetTransactionStatusQuery } from 'state/transactionStatus/slice'
import { ReponseTransactionStatus, ReponseTransactionStatusType, TransMetaData } from 'state/transactionStatus/type'
import { ThemeContext } from 'styled-components/macro'

import TransStatusCrossChain from '../ListCrossChainTransaction/TransStatusCrossChain'
import {
  ColumnLeft,
  ColumnRight,
  ContentTransactionDetails,
  Divide,
  InfoTransaction,
  InfoValue,
  TitleTradingDetail,
  TransactionDetails,
  WrapIconLoading,
  WrapNotify,
  WrapperPagination,
  WrapTransaction,
} from '../ListSwapTransaction'
import TransStatusSwap from '../ListSwapTransaction/TransStatusSwap'
import Pagination from '../Pagination'

export const baseUrl = `${process.env.REACT_APP_BACKEND_URL}`
export enum TransType {
  BRIDGE = 'bridge',
  SWAP = 'swap',
}
export function TransactionDetail({ item }: { item: ReponseTransactionStatusType }) {
  if (!item || !item.meta) return <></>
  const slippage = Number(item.meta[TransMetaData.SLIPPAGE]) / (100 * 100) || 0.1
  return (
    <TransactionDetails>
      <Divide />
      <TitleTradingDetail>Trading Detail</TitleTradingDetail>
      <ContentTransactionDetails>
        <ColumnLeft>
          <div>
            <InfoTransaction>{item.type === TransType.BRIDGE ? 'Bridge Rate' : ' Exchange Rate'}</InfoTransaction>
            <InfoTransaction>Minimum to received</InfoTransaction>
            {/* <InfoTransaction>Provider</InfoTransaction> */}
          </div>
          <div>
            <InfoValue>
              {' '}
              1 {item.tokenSwapSymbol} = {Number(item.meta[TransMetaData.EXCHANGE_RATE]).toFixed(6)}{' '}
              {item.tokenReceivedSymbol}
            </InfoValue>
            <InfoValue style={{ minWidth: 145 }}>
              {Number(
                (Number(1 - slippage) * Number(item.amountReceived)) / Math.pow(10, item.tokenReceivedDecimals || 6)
              ).toFixed(6)}{' '}
              {item.tokenReceivedSymbol}
            </InfoValue>
            {/* {item.type === TransType.SWAP && <InfoValue>{item.meta[TransMetaData.PROVIDER]}</InfoValue>} */}
          </div>
        </ColumnLeft>
        <ColumnRight>
          <div>
            <InfoTransaction>{item.type === TransType.SWAP ? ' Minimum to received' : 'Bridge Fee'}</InfoTransaction>
            <InfoTransaction>Slippage tolerance</InfoTransaction>
            {/* <InfoTransaction>Provider</InfoTransaction> */}
          </div>
          <div>
            <InfoValue>
              {item.type === TransType.SWAP
                ? `${Number((Number(item.gasLimit) * Number(item.gasPrice)) / Math.pow(10, 18)).toFixed(6)} ETH`
                : item.type === TransType.BRIDGE
                ? `${Number(item.amountSwap - item.amountReceived) / Math.pow(10, item.tokenSwapDecimals)} ${
                    item.tokenSwapSymbol
                  }`
                : ''}
            </InfoValue>
            <InfoValue>{slippage} %</InfoValue>
            {/* <InfoValue>1423</InfoValue> */}
          </div>
        </ColumnRight>
      </ContentTransactionDetails>
    </TransactionDetails>
  )
}
const ListAllTransaction = () => {
  const theme = useContext(ThemeContext)
  const [clickedIndex, setClickedIndex] = useState<any>({})
  const [page, setPage] = useState(1)
  const {
    data: valueTransaction,
    isLoading,
    error,
  } = useGetTransactionStatusQuery(
    { typeTransaction: 'all', pageNumber: page, url: baseUrl },
    {
      pollingInterval: ms`30s`,
      refetchOnMountOrArgChange: true,
    }
  )
  const handlePrevPage = (prevPage: number) => {
    setPage((prevPage) => prevPage - 1)
  }

  const handleNextPage = (nextPage: number) => {
    setPage((nextPage) => nextPage + 1)
  }
  const handleClick = (index: any) => () => {
    setClickedIndex((state: any) => ({
      ...state,
      [index]: !state[index],
    }))
  }
  const crossChainTrans =
    valueTransaction && valueTransaction?.result.data.length
      ? valueTransaction.result.data.filter((item) => item.type === TransType.BRIDGE)
      : []
  const swapTrans =
    valueTransaction && valueTransaction?.result.data.length
      ? valueTransaction.result.data.filter((item) => item.type === TransType.SWAP)
      : []
  const crossChainData: ReponseTransactionStatus = {
    result: {
      data: crossChainTrans,
      totalPage: 1,
    },
  }
  const swapData: ReponseTransactionStatus = {
    result: {
      data: swapTrans,
      totalPage: 1,
    },
  }
  return (
    <>
      <WrapTransaction>
        {isLoading || error ? (
          <WrapIconLoading>
            <HashLoader size={35} color={theme.blue1} />
          </WrapIconLoading>
        ) : (
          !valueTransaction?.result.data.length && <WrapNotify>No transaction history!</WrapNotify>
        )}
        {
          <>
            <TransStatusCrossChain
              transactions={crossChainData}
              handleOnClick={handleClick}
              clickedIndex={clickedIndex}
              setPage={setPage}
            />
          </>
        }
        {
          <>
            <TransStatusSwap
              transactions={swapData}
              handleOnClick={handleClick}
              clickedIndex={clickedIndex}
              setPage={setPage}
            />
          </>
        }
      </WrapTransaction>
      <WrapperPagination>
        <div></div>
        <Pagination
          totalPages={valueTransaction?.result.totalPage}
          currentPage={page}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
        />
      </WrapperPagination>
    </>
  )
}
export default ListAllTransaction
