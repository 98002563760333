// eslint-disable-next-line no-restricted-imports
import { Trans } from '@lingui/macro'
import { ButtonSecondary } from 'components/base/Button'
import Loader from 'components/base/Loader'
import { RowBetween } from 'components/base/Row'
import useENSName from 'hooks/ens/useENSName'
import { darken } from 'polished'
import { useMemo } from 'react'
import { Activity } from 'react-feather'
import { useToggleTransactionStatus, useWalletModalToggle } from 'state/application/hooks'
import { isTransactionRecent, useAllTransactions } from 'state/transactions/hooks'
import { TransactionDetails } from 'state/transactions/reducer'
import styled, { css } from 'styled-components/macro'
import { shortenAddress } from 'utils'
import { UnsupportedChainIdError, useWeb3React } from 'web3-react-core'

import WalletModal from '../WalletModal'

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  border-radius: 14px;
  cursor: pointer;
  user-select: none;
  height: 36px;
  margin-right: 2px;
  margin-left: 1px;
  :focus {
    outline: none;
  }
`
const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};
  color: ${({ theme }) => theme.white};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  }
`

const Web3StatusConnect = styled(Web3StatusGeneric)<{ faded?: boolean }>`
  background-color: ${({ theme }) => theme.blue1};
  border: none;
  border-radius: 4px;

  color: ${({ theme }) => theme.primaryText1};
  font-weight: 500;

  :hover,
  :focus {
    color: ${({ theme }) => theme.text8};
    border: none;
  }

  ${({ faded }) =>
    faded &&
    css`
      background-color: ${({ theme }) => theme.blue1};
      color: ${({ theme }) => theme.text8};
      border: none;

      :hover,
      :focus {
        color: ${({ theme }) => darken(0.05, theme.text8)};
        border: none;
      }
    `}
`

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean }>`
  min-width: 135px;
  background-color: ${({ pending, theme }) => (pending ? theme.green1 : theme.green1)};
  border: 1px solid ${({ pending, theme }) => (pending ? theme.green1 : theme.green1)};
  color: ${({ pending, theme }) => (pending ? theme.white : theme.text5)};
  border-radius: 4px;
  font-weight: 500;
  :hover,
  :focus {
    border: 1px solid ${({ theme }) => darken(0.05, theme.bg3)};

    :focus {
      border: 1px solid ${({ pending, theme }) => (pending ? darken(0.1, theme.primary1) : darken(0.1, theme.bg2))};
    }
  }
`

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 1rem;
  width: fit-content;
  color: #ffffff;
  font-weight: 500;
`

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

// we want the latest one to come first, so return negative if a is after b
export function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

function Web3StatusInner({
  allTransactions,
  ENSName,
}: {
  allTransactions: { [txHash: string]: TransactionDetails }
  ENSName?: string
}) {
  const { account, error } = useWeb3React()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)

  const hasPendingTransactions = !!pending.length
  const toggleWalletModal = useWalletModalToggle()

  if (account) {
    return (
      <Web3StatusConnected id="web3-status-connected" onClick={toggleWalletModal} pending={hasPendingTransactions}>
        <Text>{ENSName || shortenAddress(account)}</Text>
      </Web3StatusConnected>
    )
  } else if (error) {
    return (
      <Web3StatusError onClick={toggleWalletModal}>
        <NetworkIcon />
        <Text>{error instanceof UnsupportedChainIdError ? <Trans>Wrong Network</Trans> : <Trans>Error</Trans>}</Text>
      </Web3StatusError>
    )
  } else {
    return (
      <Web3StatusConnect id="connect-wallet" onClick={toggleWalletModal} faded={!account}>
        <Text>
          <Trans>Connect Wallet</Trans>
        </Text>
      </Web3StatusConnect>
    )
  }
}
export function PendingStatus() {
  const { account } = useWeb3React()
  const allTransactions = useAllTransactions()
  const toggleTransactionModal = useToggleTransactionStatus()
  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])
  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)

  const hasPendingTransactions = !!pending.length
  if (!account) return <></>
  return (
    <>
      {hasPendingTransactions ? (
        <Web3StatusConnected
          id="web3-status-connected"
          pending={hasPendingTransactions}
          onClick={toggleTransactionModal}
        >
          <RowBetween>
            <Text>
              <Trans>{pending?.length} Pending</Trans>
            </Text>{' '}
            <Loader stroke="white" />
          </RowBetween>
        </Web3StatusConnected>
      ) : (
        <Web3StatusConnected onClick={toggleTransactionModal}>History</Web3StatusConnected>
        // <ButtonTransaction onClick={toggleTransactionModal}></ButtonTransaction>
      )}
    </>
  )
}

export default function Web3Status({ showPending = false }: { showPending?: boolean }) {
  const { account } = useWeb3React()

  const { ENSName } = useENSName(account ?? undefined)

  const allTransactions = useAllTransactions()
  const pending = useFilterTransactionByCondition((tx: TransactionDetails) => !tx?.receipt).map((tx) => tx.hash)
  const confirmed = useFilterTransactionByCondition((tx: TransactionDetails) => tx?.receipt).map((tx) => tx.hash)

  return (
    <>
      <Web3StatusInner ENSName={ENSName ?? undefined} allTransactions={allTransactions} />
      <WalletModal ENSName={ENSName ?? undefined} pendingTransactions={pending} confirmedTransactions={confirmed} />
    </>
  )
}

export const useFilterTransactionByCondition = (f: any) => {
  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  if (sortedRecentTransactions.length < 1) return []
  const rs = sortedRecentTransactions.filter(f)
  return rs
}
