import { getParsedNetworkId } from 'components/layout/Header/NetworkSelector'
import { NETWORK_INFO } from 'constants/networks'
import useActiveWeb3 from 'hooks/blockchain/useActiveWeb3'
import useParsedQueryString from 'hooks/environment/useParsedQueryString'
import { useCurrency } from 'hooks/tokens/Tokens'
import { useAppSelector } from 'state/hooks'
import { Field } from 'state/swap/actions'
import { useSwapState } from 'state/swap/hooks'
import { CheckTransactionType } from 'state/transactions/actions'
import getTransactionType from 'utils/getTransactionType'
export interface TransactionTitleAndLabel {
  cardTitle: string
  cardLabel: string
  inputNetworkId: number
  outputNetworkId: number
  tradeType: CheckTransactionType
}
export const capitalizeFirstLetter = (string: string) => string[0].toUpperCase() + string.slice(1)
const useGetTransactionTitleAndLabel = (): TransactionTitleAndLabel => {
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },

    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()
  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)
  const transactionType = getTransactionType(inputCurrency, outputCurrency)
  const cardLabel = capitalizeFirstLetter(transactionType.toLowerCase().split('_').join(' '))

  const inputChainId = useAppSelector((state) => state.swap.INPUT.chainId)
  const { networkId } = useActiveWeb3()

  const parsedQs = useParsedQueryString()
  const { urlNetworkId } = getParsedNetworkId(parsedQs)
  const inputNetworkId = networkId || urlNetworkId || inputChainId || 1
  const outputNetworkId =
    useAppSelector((state) => state.outputNetworkId || state.swap.OUTPUT.chainId) || urlNetworkId || 1
  const { label: inputNetwokLabel } = NETWORK_INFO[inputNetworkId]

  let title = `On ${inputNetwokLabel} network`
  switch (transactionType) {
    case CheckTransactionType.BRIDGE:
      const { label: outputNetworkLabel } = NETWORK_INFO[outputNetworkId]
      title = `Bridge on ${inputNetwokLabel} - ${outputNetworkLabel} networks`
      break
    case CheckTransactionType.CROSS_CHAIN:
      const { label: outputLabel } = NETWORK_INFO[outputNetworkId]
      title = `Cross chain on ${inputNetwokLabel} - ${outputLabel} networks`
      break
    default:
      title = `On ${inputNetwokLabel} network`
      break
  }
  return {
    cardLabel,
    cardTitle: title,
    inputNetworkId,
    outputNetworkId,
    tradeType: transactionType,
  }
}
export default useGetTransactionTitleAndLabel
