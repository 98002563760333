import type { ComboBoxProps } from '@react-types/combobox'
import * as React from 'react'
import { useComboBox } from 'react-aria'
import { useComboBoxState } from 'react-stately'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components/macro'

import { ReactComponent as SearchBlack } from '../../../assets/svg/Search-black.svg'
import { ReactComponent as SearchWhite } from '../../../assets/svg/Search-white.svg'
import { ListBox } from './ListBox'
import { Popover } from './Popover'

export { Item } from 'react-stately'

const SearchContainer = styled.div`
  position: relative;
  width: 456px;
  height: 40px;
  background: ${({ theme }) => theme.bg0};
  border-radius: 8px;
  display: flex;
  border: 1 solid ${({ theme }) => theme.bg8};
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;
  transition: all 0.3s ease;
  margin-left: -1.2rem;
`
const IconSearch = styled.button`
  position: relative;
  height: 20px;
  width: 20px;
  border: none;
  z-index: 1;
  cursor: pointer;
  background: none;
`
const SearchInput = styled.input`
  padding-left: 18px;
  border: none;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: transparent;
  outline: none;
  font-size: 16px;
  color: ${({ theme }) => theme.text1};
  border: 1px solid transparent;

  &:focus {
    border-color: none;
  }
`

const WrapNoResult = styled.p`
  position: absolute;
  box-sizing: border-box;
  width: 456px;
  height: 50px;
  background: ${({ theme }) => theme.bg0};
  border: 1px solid ${({ theme }) => theme.bg8};
  border-radius: 8px;
  font-size: 14px;
  height: 50px;
  padding-top: 14px;
  top: 2rem;
  left: 0rem;
  text-align: center;
`

export function SearchAutocomplete<T extends Record<string, unknown>>(props: ComboBoxProps<T>) {
  const [darkMode] = useDarkModeManager()

  const state = useComboBoxState({ ...props })

  React.useEffect(() => {
    if (state.collection.size && props.inputValue) {
      state.open()
    }
  }, [state, props.inputValue])

  const inputRef = React.useRef(null)
  const listBoxRef = React.useRef(null)
  const popoverRef = React.useRef(null)

  const { inputProps, listBoxProps, labelProps } = useComboBox(
    {
      ...props,
      inputRef,
      listBoxRef,
      popoverRef,
    },
    state
  )

  return (
    <div>
      {props.label && <label {...labelProps}>{props.label}</label>}
      <SearchContainer>
        <IconSearch>{darkMode ? <SearchWhite /> : <SearchBlack />}</IconSearch>
        <SearchInput {...inputProps} ref={inputRef} placeholder="Search by token name..." />
      </SearchContainer>
      {state.isOpen && (
        <Popover popoverRef={popoverRef} isOpen={state.isOpen} onClose={state.close}>
          <ListBox {...listBoxProps} listBoxRef={listBoxRef} state={state} />
        </Popover>
      )}
      {state.collection.size === 0 && props.inputValue && (
        <WrapNoResult>Sorry, we can&apos;t find &quot;{state.inputValue}&quot;</WrapNoResult>
      )}
    </div>
  )
}
