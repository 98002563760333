/* eslint-disable simple-import-sort/imports */
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { load, save } from 'redux-localstorage-simple'
import multicall from 'state/transactions/multicall'
import { updateAppVersion } from 'utils/updateAppVersion'

import application from './application/reducer'
import { chartInfoApi } from './chartInfo/slice'
import priceMultifull from './chartPriceMultifull/reducer'
import { api as dataApi } from './data/slice'
import { gasPriceApi } from './gasPrice/slice'
import { updateVersion } from './global/actions'
import liquidityProvider from './liquidityProvider/reducer'
import lists from './lists/reducer'
import logs from './logs/slice'
import outputNetworkId from './network/reducer'
import { priceTokenApi } from './priceToken/slice'
import { quoteApi } from './quote/slice'
import { routingApi } from './routing/slice'
import { transactionsHistoryApi } from './transactions/slice'
import swap from './swap/reducer'
import tokens from './tokens/reducer'
import { tokenSupportedApi, tokenWhatsHotApi, tokenTopCoinsApi } from './tokens/slice'
import transactions from './transactions/reducer'
import user from './user/reducer'

const PERSISTED_KEYS: string[] = ['user', 'transactions', 'lists']

const store = configureStore({
  reducer: {
    application,
    user,
    transactions,
    swap,
    tokens,
    multicall: multicall.reducer,
    lists,
    logs,
    liquidityProvider,
    priceMultifull,
    outputNetworkId,
    [dataApi.reducerPath]: dataApi.reducer,
    [routingApi.reducerPath]: routingApi.reducer,
    [quoteApi.reducerPath]: quoteApi.reducer,
    [priceTokenApi.reducerPath]: priceTokenApi.reducer,
    [transactionsHistoryApi.reducerPath]: transactionsHistoryApi.reducer,
    [gasPriceApi.reducerPath]: gasPriceApi.reducer,
    [chartInfoApi.reducerPath]: chartInfoApi.reducer,
    [tokenSupportedApi.reducerPath]: tokenSupportedApi.reducer,
    [tokenWhatsHotApi.reducerPath]: tokenWhatsHotApi.reducer,
    [tokenTopCoinsApi.reducerPath]: tokenTopCoinsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true, serializableCheck: false })
      .concat(dataApi.middleware)
      .concat(routingApi.middleware)
      .concat(quoteApi.middleware)
      .concat(gasPriceApi.middleware)
      .concat(priceTokenApi.middleware)
      .concat(transactionsHistoryApi.middleware)
      .concat(tokenSupportedApi.middleware)
      .concat(tokenWhatsHotApi.middleware)
      .concat(tokenTopCoinsApi.middleware)
      .concat(save({ states: PERSISTED_KEYS, debounce: 1000 })),
  preloadedState: !updateAppVersion()
    ? load({ states: PERSISTED_KEYS, disableWarnings: process.env.NODE_ENV === 'test' })
    : undefined,
})

store.dispatch(updateVersion())

setupListeners(store.dispatch)

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
