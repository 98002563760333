import { SupportedNetworkId } from '../constants/networks'

/**
 * Returns the input chain ID if chain is supported. If not, return undefined
 * @param networkId a chain ID, which will be returned if it is a supported chain ID
 */
export function supportedNetworkId(networkId: number | undefined): SupportedNetworkId | undefined {
  if (typeof networkId === 'number' && networkId in SupportedNetworkId) {
    return networkId
  }
  return undefined
}
