import { BaseProvider, JsonRpcProvider } from '@ethersproject/providers'
import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { Protocol } from '@uniswap/router-sdk'
import { ChainId } from '@uniswap/smart-order-router'
import { EVM_NETWORK_IDS, INFURA_NETWORK_URLS, SupportedNetworkId } from 'constants/networks'
import { getClientSideQuote } from 'hooks/routing/clientSideSmartOrderRouter'
import ms from 'ms.macro'
import qs from 'qs'

import { GetQuoteResult } from './types'

const routerProviders = new Map<ChainId, BaseProvider>()
function getRouterProvider(networkIdNumber: number): BaseProvider {
  const provider = routerProviders.get(networkIdNumber)
  if (provider) return provider

  if (EVM_NETWORK_IDS.includes(networkIdNumber)) {
    const provider = new JsonRpcProvider(INFURA_NETWORK_URLS[networkIdNumber])
    routerProviders.set(networkIdNumber, provider)
    return provider
  }

  throw new Error(`Router does not support this chain (network: ${networkIdNumber}).`)
}

const protocols: Protocol[] = [Protocol.V2, Protocol.V3]

const DEFAULT_QUERY_PARAMS = {
  protocols: protocols.map((p) => p.toLowerCase()).join(','),
}

export const routingApi = createApi({
  reducerPath: 'routingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.uniswap.org/v1/',
  }),
  endpoints: (build) => ({
    getQuote: build.query<
      GetQuoteResult,
      {
        tokenInAddress: string
        tokenInNetworkId: SupportedNetworkId
        tokenInDecimals: number
        tokenInSymbol?: string
        tokenOutAddress: string
        tokenOutNetworkId: SupportedNetworkId
        tokenOutDecimals: number
        tokenOutSymbol?: string
        amount: string
        useClientSideRouter: boolean // included in key to invalidate on change
        type: 'exactIn' | 'exactOut'
      }
    >({
      async queryFn(args, _api, _extraOptions, fetch) {
        const {
          tokenInAddress,
          tokenInNetworkId,
          tokenOutAddress,
          tokenOutNetworkId,
          amount,
          useClientSideRouter,
          type,
        } = args

        let result

        try {
          if (useClientSideRouter) {
            const networkIdNumber: number = args.tokenInNetworkId
            const params = { chainId: networkIdNumber, provider: getRouterProvider(networkIdNumber) }
            result = await getClientSideQuote(args, params, { protocols })
          } else {
            const query = qs.stringify({
              ...DEFAULT_QUERY_PARAMS,
              tokenInAddress,
              tokenInNetworkId,
              tokenOutAddress,
              tokenOutNetworkId,
              amount,
              type,
            })
            result = await fetch(`quote?${query}`)
          }

          return { data: result.data as GetQuoteResult }
        } catch (e) {
          // TODO: fall back to client-side quoter when auto router fails.
          // deprecate 'legacy' v2/v3 routers first.
          return { error: e as FetchBaseQueryError }
        }
      },
      keepUnusedDataFor: ms`10s`,
      extraOptions: {
        maxRetries: 0,
      },
    }),
  }),
})

export const { useGetQuoteQuery } = routingApi
