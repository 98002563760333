export const CHAIN_LIST = {
  ethereum: 1,
  'polygon-pos': 137,
  avalanche: 43114,
  'optimistic-ethereum': 10,
}
export const TOKEN_DEFAULT = {
  [CHAIN_LIST.ethereum]: { name: 'Ether', symbol: 'ETH', address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2' },
  [CHAIN_LIST['polygon-pos']]: {
    name: 'Polygon Matic',
    symbol: 'MATIC',
    address: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
  },
  [CHAIN_LIST.avalanche]: { name: 'Avalanche', symbol: 'AVAX', address: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7' },
  [CHAIN_LIST['optimistic-ethereum']]: {
    name: 'Ethereum',
    symbol: 'ETH',
    address: '0x4200000000000000000000000000000000000006',
  },
}
