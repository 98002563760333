const requestRefund = async ({ transferId }: { transferId: string }) => {
  const data = {
    transferID: transferId,
  }

  const accessToken = localStorage.getItem('accessToken')
  const result = await fetch(`${process.env.REACT_APP_BACKEND_URL}/transaction/refund`, {
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    method: 'POST',
  })
  const rs = await result.json()

  return rs
}
export default requestRefund
