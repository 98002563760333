import { Trans } from '@lingui/macro'
import useActiveWeb3 from 'hooks/blockchain/useActiveWeb3'
import styled from 'styled-components/macro'

import { useAppDispatch } from '../../../state/hooks'
import { Field, replaceSwapState } from '../../../state/swap/actions'
import Copy from './Copy'

const AccountControl = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 0;
  width: 100%;
  font-weight: 400;
  font-size: 1.25rem;

  a:hover {
    text-decoration: underline;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const MenyFlyout = styled.span`
  min-width: 196px;
  max-height: 350px;
  overflow: auto;
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.01), 0 4px 8px rgba(0, 0, 0, 0.04), 0 16px 24px rgba(0, 0, 0, 0.04),
    0 24px 32px rgba(0, 0, 0, 0.01);
  border: 1px solid ${({ theme }) => theme.bg0};
  border-radius: 12px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: absolute;
  top: 3rem;
  z-index: 100;
  /* margin-left: 11rem;
  margin-top: 16px; */
  right: calc(50% - 586px);
  margin-top: 1rem;
`

const WalletAction = styled.button`
  background-color: transparent;
  border: none;
  width: fit-content;
  font-weight: 400;
  margin-left: 8px;
  font-size: 0.825rem;
  color: ${({ theme }) => theme.text1};
  padding: 4px 6px;

  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.green1};
  }
`

interface AccountDetailsProps {
  toggleWalletModal: () => void
  pendingTransactions: string[]
  confirmedTransactions: string[]
  ENSName?: string
  openOptions: () => void
}

export default function AccountDetails({ openOptions }: AccountDetailsProps) {
  const { account, deactivate } = useActiveWeb3()

  const dispatch: any = useAppDispatch()

  const handleDisconnectWallet = () => {
    deactivate()
    localStorage.setItem('isDisconnected', 'true')
    localStorage.removeItem('accessToken')
    dispatch(
      replaceSwapState({
        typedValue: '',
        field: Field.INPUT,
        inputCurrency: { currencyId: null },
        outputCurrency: { currencyId: null },
        recipient: null,
      })
    )
  }

  return (
    <>
      <MenyFlyout>
        <WalletAction
          style={{ fontSize: '.825rem', fontWeight: 400, marginRight: '8px' }}
          onClick={handleDisconnectWallet}
        >
          <Trans>Disconnect</Trans>
        </WalletAction>
        <WalletAction
          style={{ fontSize: '.825rem', fontWeight: 400 }}
          onClick={() => {
            openOptions()
          }}
        >
          <Trans>Change</Trans>
        </WalletAction>
        <AccountControl>
          {account && (
            <Copy toCopy={account}>
              <span style={{ marginLeft: '4px' }}>
                <Trans>Copy Address</Trans>
              </span>
            </Copy>
          )}
        </AccountControl>
      </MenyFlyout>
    </>
  )
}
