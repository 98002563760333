import { NETWORK_IDS_TO_NAMES, NETWORK_INFO, SupportedNetworkId } from 'constants/networks'
import useActiveWeb3 from 'hooks/blockchain/useActiveWeb3'
import { useOnClickOutside } from 'hooks/environment/useOnClickOutside'
import useParsedQueryString from 'hooks/environment/useParsedQueryString'
import usePrevious from 'hooks/environment/usePrevious'
import { ParsedQs } from 'qs'
import { useCallback, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useModalOpen, useToggleModal } from 'state/application/hooks'
import { addPopup, ApplicationModal } from 'state/application/reducer'
import { useAppDispatch } from 'state/hooks'
import styled from 'styled-components/macro'
import { MEDIA_WIDTHS } from 'theme'
import { replaceURLParam } from 'utils/routes'
import { switchToNetwork } from 'utils/switchToNetwork'

interface NetworkSelectorProps {
  height: number
}

const ActiveRowWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  cursor: pointer;
`
const FlyoutMenu = styled.div`
  /* padding: 24px; */
  border-radius: 0 8px 0 0;
  border: 1px solid ${({ theme }) => theme.bg8};
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    top: 40px;
  }
  height: 492px;
  overflow: auto;
  background-color: ${({ theme }) => theme.bg0};
`
const FlyoutMenuContents = styled.div<{ height: number }>`
  background-color: ${({ theme }) => theme.bg0};
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height}px;
  font-size: 16px;
  overflow: auto;
  padding-top: 20px;
  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`
const FlyoutRow = styled.div<{ active: boolean }>`
  background-color: ${({ active, theme }) => (active ? theme.green2 : theme.bg0)};
  cursor: pointer;
  display: flex;
  font-weight: 500;
  padding: 12px;
  text-align: left;
`

const Logo = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 24px;
  margin-left: 12px;
`
const SelectorWrapper = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  width: 420px;
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    position: relative;
  }
`

function Row({
  targetNetwork,
  onSelectNetwork,
}: {
  targetNetwork: SupportedNetworkId
  onSelectNetwork: (targetNetwork: number) => void
}) {
  const { library, networkId } = useActiveWeb3()
  const toggle = useToggleModal(ApplicationModal.NETWORK_SELECTOR)
  if (!library || !networkId) {
    return null
  }
  const active = networkId === targetNetwork
  const { logoUrl, label } = NETWORK_INFO[targetNetwork]
  const rowContent = (
    <FlyoutRow
      onClick={() => {
        onSelectNetwork(targetNetwork)
      }}
      active={active}
    >
      <Logo src={logoUrl} />
      {label}
    </FlyoutRow>
  )

  if (active) {
    return <ActiveRowWrapper>{rowContent}</ActiveRowWrapper>
  }
  return rowContent
}

export const getParsedNetworkId = (parsedQs?: ParsedQs) => {
  const network = parsedQs?.network
  if (!network || typeof network !== 'string') return { urlNetwork: undefined, urlNetworkId: undefined }

  return { urlNetwork: network.toLowerCase(), urlNetworkId: getNetworkIdFromName(network) }
}

const getNetworkIdFromName = (name: string) => {
  const entry = Object.entries(NETWORK_IDS_TO_NAMES).find(([_, n]) => n === name)
  const networkId = entry?.[0]
  return networkId ? parseInt(networkId) : undefined
}

const getNetworkNameFromId = (id: string | number) => {
  // casting here may not be right but fine to return undefined if it's not a supported network ID
  return NETWORK_IDS_TO_NAMES[id as SupportedNetworkId] || ''
}

export default function NetworkSelector({ height }: NetworkSelectorProps) {
  const { networkId, library } = useActiveWeb3()
  const parsedQs = useParsedQueryString()
  const { urlNetwork, urlNetworkId } = getParsedNetworkId(parsedQs)
  const prevNetworkId = usePrevious(networkId)
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.NETWORK_SELECTOR)
  const toggle = useToggleModal(ApplicationModal.NETWORK_SELECTOR)
  useOnClickOutside(node, open ? toggle : undefined)
  const history = useHistory()

  const info = networkId ? NETWORK_INFO[networkId] : undefined

  const dispatch = useAppDispatch()

  const handleNetworkSwitch = useCallback(
    (targetNetwork: number, skipToggle?: boolean) => {
      if (!library) return
      switchToNetwork({ library, networkId: targetNetwork })
        .then(() => {
          if (!skipToggle) {
            toggle()
          }
          history.replace({
            search: replaceURLParam(history.location.search, 'network', getNetworkNameFromId(targetNetwork)),
          })
        })
        .catch((error) => {
          console.error('Failed to switch networks', error)

          // we want app network <-> networkId param to be in sync, so if user changes the network by changing the URL
          // but the request fails, revert the URL back to current networkId
          if (networkId) {
            history.replace({
              search: replaceURLParam(history.location.search, 'network', getNetworkNameFromId(networkId)),
            })
          }

          if (!skipToggle) {
            toggle()
          }
          dispatch(addPopup({ content: { failedSwitchNetwork: targetNetwork }, key: `failed-network-switch` }))
        })
    },
    [dispatch, library, toggle, history, networkId]
  )

  useEffect(() => {
    if (!networkId || !prevNetworkId) return

    // when network change originates from wallet or dropdown selector, just update URL
    if (networkId !== prevNetworkId) {
      history.replace({ search: replaceURLParam(history.location.search, 'network', getNetworkNameFromId(networkId)) })
      // otherwise assume network change originates from URL
    } else if (urlNetworkId && urlNetworkId !== networkId) {
      handleNetworkSwitch(urlNetworkId, true)
    }
  }, [networkId, urlNetworkId, prevNetworkId, handleNetworkSwitch, history])

  // set network parameter on initial load if not there
  useEffect(() => {
    if (networkId && !urlNetworkId) {
      history.replace({ search: replaceURLParam(history.location.search, 'network', getNetworkNameFromId(networkId)) })
    }
  }, [networkId, history, urlNetworkId, urlNetwork])

  if (!networkId || !info || !library) {
    return null
  }

  return (
    <SelectorWrapper height={height} ref={node as any} onMouseEnter={toggle} onMouseLeave={toggle}>
      <FlyoutMenu>
        <FlyoutMenuContents height={height}>
          <Row onSelectNetwork={handleNetworkSwitch} targetNetwork={SupportedNetworkId.MAINNET} />
          <Row onSelectNetwork={handleNetworkSwitch} targetNetwork={SupportedNetworkId.POLYGON} />
          <Row onSelectNetwork={handleNetworkSwitch} targetNetwork={SupportedNetworkId.POLYGON_MUMBAI} />
          {/* <Row onSelectNetwork={handleNetworkSwitch} targetNetwork={SupportedNetworkId.RINKEBY} /> */}
          <Row onSelectNetwork={handleNetworkSwitch} targetNetwork={SupportedNetworkId.ROPSTEN} />
        </FlyoutMenuContents>
      </FlyoutMenu>
    </SelectorWrapper>
  )
}
