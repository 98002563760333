// eslint-disable-next-line no-restricted-imports
import { t } from '@lingui/macro'

import { ReactComponent as DashboardIcon } from '../../../assets/svg/dashboard.svg'
import { ReactComponent as DashboardActive } from '../../../assets/svg/dashboard-1.svg'
import { ReactComponent as PortfolioIcon } from '../../../assets/svg/portfolio.svg'
import { ReactComponent as PortfolioActive } from '../../../assets/svg/portfolio-1.svg'
import { ReactComponent as Trade } from '../../../assets/svg/swap.svg'
import { ReactComponent as TradeActive } from '../../../assets/svg/swap-1.svg'

export const SidebarData = [
  {
    isPrivate: false,
    title: t`Dashboard`,
    path: '/dashboard',
    icon: <DashboardIcon />,
    iconActive: <DashboardActive />,
  },
  {
    isPrivate: true,
    title: t`Portfolio`,
    path: '/portfolio',
    icon: <PortfolioIcon />,
    iconActive: <PortfolioActive />,
  },
  {
    isPrivate: false,
    title: t`Trade`,
    path: '/swap',
    icon: <Trade />,
    iconActive: <TradeActive />,
  },
]
