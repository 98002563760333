import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { QuoteType } from 'hooks/swap/useLiquidityProvider'
import ms from 'ms.macro'
import qs from 'qs'

import { GetQuoteResposeType } from './../../hooks/swap/useLiquidityProvider'

interface QuoteParams {
  params: QuoteType
  url?: string
}

export const quoteApi = createApi({
  reducerPath: 'quoteApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '',
  }),
  endpoints: (build) => ({
    getQuoteData: build.query<GetQuoteResposeType, QuoteParams>({
      async queryFn(args, _api, _extraOptions, fetch) {
        try {
          const query = qs.stringify(args.params)
          const result = await fetch(`${args.url}/quote?${query}`)
          return result as any
        } catch (error) {
          return { error: error as any }
        }
      },
      keepUnusedDataFor: ms`60s`,
      extraOptions: {
        maxRetries: 0,
      },
    }),
  }),
})

export const { useGetQuoteDataQuery } = quoteApi
