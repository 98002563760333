import React from 'react'

type TabProps = {
  title: string
}

// eslint-disable-next-line react/prop-types
const Tab: React.FC<TabProps> = ({ children }) => {
  return <div style={{ width: '100%' }}>{children}</div>
}

export default Tab
