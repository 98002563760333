import useActiveWeb3 from 'hooks/blockchain/useActiveWeb3'
import React, { FC, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDarkModeManager } from 'state/user/hooks'
import styled, { useTheme } from 'styled-components/macro'

import { ReactComponent as Burger } from '../../../assets/svg/burger.svg'
import { ReactComponent as LogoInt3face } from '../../../assets/svg/Int3face_logo.svg'
import { ReactComponent as LogoInt3faceLight } from '../../../assets/svg/Int3face_logo-1.svg'
import { ReactComponent as Logo } from '../../../assets/svg/logo.svg'
import { ReactComponent as Moon } from '../../../assets/svg/moon.svg'
import { ReactComponent as Sun } from '../../../assets/svg/sun.svg'
import { SidebarData } from './SidebarData'

const LogoHeader = styled.div`
  display: flex;
  margin-bottom: 24px;
  position: relative;
`
const IconLogo = styled(Logo)`
  width: 34px;
  height: 42px;
`

const SidebarWrapper = styled.div`
  display: flex;
  height: 100vh;
  position: sticky;
  top: 0;
`

const SidebarMenu = styled.div<{ close: boolean }>`
  width: ${({ close }) => (close ? '208px' : '60px')};
  background-color: ${({ theme }) => theme.bg0};
  position: relative;
  border: 1px solid ${({ theme }) => theme.bg8};
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  transition: 0.6s;
  margin-top: 1.5rem;
  padding-bottom: 2rem;
  margin-left: 1.5rem;
  margin-bottom: 2rem;
  margin-right: 1rem;
  z-index: 2;
`
const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  font-weight: 600;
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  margin-right: 12px;
  margin-left: 15px;
  margin-top: 35px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`
const MenuIconClose = styled(Burger)<{ open: boolean }>`
  position: absolute;
  padding: 5px;
  display: flex;
  font-size: 1.5rem;
  width: 24px;
  top: 2.6rem;
  border-radius: 50%;
  height: 24px;
  border: 1px solid ${({ theme }) => theme.bg8};
  right: -1rem;
  cursor: pointer;
  justify-content: flex-end;
  background-color: #005ece;
  transform: ${({ open }) => (!open ? `rotate(180deg)` : `initial`)};
`
const MenuItems = styled.li<{ active: boolean }>`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 40px;
  padding: 1.5rem 0 1.25rem;
  background: ${({ active, theme }) => (active ? theme.green2 : theme.bg0)};
  margin-top: 16px;
`
const MenuItemLinks = styled(Link)<{ active: boolean }>`
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  color: ${({ active, theme }) => (active ? '#005ECE' : theme.text7)};
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  margin-left: 1.2rem;
`
const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.bg8};
`
const IconDarkMode = styled.button`
  background: ${({ theme }) => theme.green1};
  border-radius: 100px;
  width: 36px;
  height: 20px;
  margin: 0 auto;
  margin-top: auto;
  cursor: pointer;
  border: none;
  color: #ffffff;
`
const ButtonDarkMode = styled.button`
  background: ${({ theme }) => theme.green1};
  border-radius: 100px;
  width: 176px;
  height: 28px;
  margin: 0 auto;
  margin-top: auto;
  cursor: pointer;
  border: none;
  color: #ffffff;
`

const Sidebar: FC = () => {
  const { pathname } = useLocation()
  const [openSideBar, setOpenSideBar] = useState<boolean>(true)
  const [darkMode, toggleDarkMode] = useDarkModeManager()
  const { white, black } = useTheme()
  const showSidebar = () => {
    setOpenSideBar(!openSideBar)
  }
  const { account } = useActiveWeb3()

  const menuItemData = SidebarData.filter((item) => !item?.isPrivate || account)

  return (
    <SidebarWrapper>
      <SidebarMenu close={openSideBar}>
        <LogoHeader>
          <Title href=".">
            {openSideBar ? (
              darkMode ? (
                <LogoInt3faceLight style={{ marginLeft: '13px' }} />
              ) : (
                <LogoInt3face style={{ marginLeft: '13px' }} />
              )
            ) : (
              <IconLogo fill={darkMode ? white : black} width="103px" height="100%" title="logo" />
            )}
          </Title>
          <MenuIconClose open={openSideBar} to="#" onClick={showSidebar} />
        </LogoHeader>
        <Divider />
        {menuItemData.map((item, index) => {
          return (
            <MenuItems key={index} active={pathname === item.path}>
              <MenuItemLinks to={item.path} active={pathname === item.path}>
                {pathname === item.path ? item.iconActive : item.icon}
                {openSideBar && <span style={{ marginLeft: '16px' }}>{item.title}</span>}
              </MenuItemLinks>
            </MenuItems>
          )
        })}
        {openSideBar ? (
          <ButtonDarkMode onClick={toggleDarkMode}>{darkMode ? 'Light view' : 'Dark view'}</ButtonDarkMode>
        ) : (
          <IconDarkMode onClick={toggleDarkMode}>{darkMode ? <Sun fill="#ffffff" /> : <Moon />}</IconDarkMode>
        )}
      </SidebarMenu>
    </SidebarWrapper>
  )
}

export default Sidebar
