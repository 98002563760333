import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { setOutputNetworkId } from './reducer'
export function useSetOutputNetworkId(): (data: number) => void {
  const dispatch = useDispatch()
  return useCallback(
    (data: number) => {
      dispatch(setOutputNetworkId(data))
    },
    [dispatch]
  )
}
