import '@reach/dialog/styles.css'
import 'inter-ui'
import 'polyfills'
import './theme/global.css'

import { BlockUpdater } from 'hooks/blockchain/useBlockNumber'
import { I18nProvider } from 'lib/locales'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import { MulticallUpdater } from 'state/transactions/multicall'
import { createWeb3ReactRoot, Web3ReactProvider } from 'web3-react-core'

import AddressCheck from './components/account/AddressCheck'
import { NetworkContextName } from './constants/misc'
import App from './pages/App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import store from './state'
import ApplicationUpdater from './state/application/updater'
import ListsUpdater from './state/lists/updater'
import LogsUpdater from './state/logs/updater'
import TransactionUpdater from './state/transactions/updater'
import UserUpdater from './state/user/updater'
import ThemeProvider, { ThemedGlobalStyle } from './theme'
import getLibrary from './utils/getLibrary'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <HashRouter>
        <I18nProvider>
          <Web3ReactProvider getLibrary={getLibrary}>
            <Web3ProviderNetwork getLibrary={getLibrary}>
              <AddressCheck>
                <ListsUpdater />
                <UserUpdater />
                <ApplicationUpdater />
                <TransactionUpdater />
                <BlockUpdater />
                <MulticallUpdater />
                <LogsUpdater />
                <ThemeProvider>
                  <ThemedGlobalStyle />
                  <App />
                </ThemeProvider>
              </AddressCheck>
            </Web3ProviderNetwork>
          </Web3ReactProvider>
        </I18nProvider>
      </HashRouter>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
)

if (process.env.REACT_APP_SERVICE_WORKER !== 'false') {
  serviceWorkerRegistration.register()
}
