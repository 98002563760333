import { Currency, Ether, NativeCurrency, Token, WETH9 } from '@uniswap/sdk-core'
import {
  ChainId,
  USDC_GÖRLI,
  USDC_MAINNET,
  USDC_POLYGON,
  USDC_POLYGON_MUMBAI,
  USDC_RINKEBY,
  USDC_ROPSTEN,
} from '@uniswap/smart-order-router'
import invariant from 'tiny-invariant'

import { UNI_ADDRESS } from './addresses'
import { SupportedNetworkId } from './networks'

export { USDC_MAINNET, USDC_POLYGON }

export const AMPL = new Token(
  SupportedNetworkId.MAINNET,
  '0xD46bA6D942050d489DBd938a2C909A5d5039A161',
  9,
  'AMPL',
  'Ampleforth'
)
export const DAI = new Token(
  SupportedNetworkId.MAINNET,
  '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const USDC: { [networkId in SupportedNetworkId]: Token } = {
  [SupportedNetworkId.MAINNET]: USDC_MAINNET,
  [SupportedNetworkId.POLYGON]: USDC_POLYGON,
  [SupportedNetworkId.POLYGON_MUMBAI]: USDC_POLYGON_MUMBAI,
  [SupportedNetworkId.GOERLI]: USDC_GÖRLI,
  [SupportedNetworkId.RINKEBY]: USDC_RINKEBY,
  [SupportedNetworkId.ROPSTEN]: USDC_ROPSTEN,
}
export const DAI_POLYGON = new Token(
  SupportedNetworkId.POLYGON,
  '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const USDT_POLYGON = new Token(
  SupportedNetworkId.POLYGON,
  '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
  6,
  'USDT',
  'Tether USD'
)
export const WBTC_POLYGON = new Token(
  SupportedNetworkId.POLYGON,
  '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
  8,
  'WBTC',
  'Wrapped BTC'
)
export const USDT = new Token(
  SupportedNetworkId.MAINNET,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD'
)
export const WBTC = new Token(
  SupportedNetworkId.MAINNET,
  '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  8,
  'WBTC',
  'Wrapped BTC'
)
export const FEI = new Token(
  SupportedNetworkId.MAINNET,
  '0x956F47F50A910163D8BF957Cf5846D573E7f87CA',
  18,
  'FEI',
  'Fei USD'
)
export const TRIBE = new Token(
  SupportedNetworkId.MAINNET,
  '0xc7283b66Eb1EB5FB86327f08e1B5816b0720212B',
  18,
  'TRIBE',
  'Tribe'
)
export const FRAX = new Token(
  SupportedNetworkId.MAINNET,
  '0x853d955aCEf822Db058eb8505911ED77F175b99e',
  18,
  'FRAX',
  'Frax'
)
export const FXS = new Token(
  SupportedNetworkId.MAINNET,
  '0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0',
  18,
  'FXS',
  'Frax Share'
)
export const renBTC = new Token(
  SupportedNetworkId.MAINNET,
  '0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D',
  8,
  'renBTC',
  'renBTC'
)
export const ETH2X_FLI = new Token(
  SupportedNetworkId.MAINNET,
  '0xAa6E8127831c9DE45ae56bB1b0d4D4Da6e5665BD',
  18,
  'ETH2x-FLI',
  'ETH 2x Flexible Leverage Index'
)
export const sETH2 = new Token(
  SupportedNetworkId.MAINNET,
  '0xFe2e637202056d30016725477c5da089Ab0A043A',
  18,
  'sETH2',
  'StakeWise Staked ETH2'
)
export const rETH2 = new Token(
  SupportedNetworkId.MAINNET,
  '0x20BC832ca081b91433ff6c17f85701B6e92486c5',
  18,
  'rETH2',
  'StakeWise Reward ETH2'
)
export const SWISE = new Token(
  SupportedNetworkId.MAINNET,
  '0x48C3399719B582dD63eB5AADf12A40B4C3f52FA2',
  18,
  'SWISE',
  'StakeWise'
)
export const WETH_POLYGON_MUMBAI = new Token(
  SupportedNetworkId.POLYGON_MUMBAI,
  '0xa6fa4fb5f76172d178d61b04b0ecd319c5d1c0aa',
  18,
  'WETH',
  'Wrapped Ether'
)

export const WETH_POLYGON = new Token(
  SupportedNetworkId.POLYGON,
  '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
  18,
  'WETH',
  'Wrapped Ether'
)
export const UNI: { [networkId: number]: Token } = {
  [SupportedNetworkId.MAINNET]: new Token(SupportedNetworkId.MAINNET, UNI_ADDRESS[1], 18, 'UNI', 'Uniswap'),
  [SupportedNetworkId.RINKEBY]: new Token(SupportedNetworkId.RINKEBY, UNI_ADDRESS[4], 18, 'UNI', 'Uniswap'),
  [SupportedNetworkId.ROPSTEN]: new Token(SupportedNetworkId.ROPSTEN, UNI_ADDRESS[3], 18, 'UNI', 'Uniswap'),
  [SupportedNetworkId.GOERLI]: new Token(SupportedNetworkId.GOERLI, UNI_ADDRESS[5], 18, 'UNI', 'Uniswap'),
}

export const WRAPPED_NATIVE_CURRENCY: { [networkId: number]: Token | undefined } = {
  ...(WETH9 as Record<SupportedNetworkId, Token>),
  [SupportedNetworkId.POLYGON]: new Token(
    SupportedNetworkId.POLYGON,
    '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    18,
    'WMATIC',
    'Wrapped MATIC'
  ),
  [SupportedNetworkId.POLYGON_MUMBAI]: new Token(
    SupportedNetworkId.POLYGON_MUMBAI,
    '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
    18,
    'WMATIC',
    'Wrapped MATIC'
  ),
}

function isMatic(networkId: number): networkId is ChainId.POLYGON | ChainId.POLYGON_MUMBAI {
  return networkId === SupportedNetworkId.POLYGON_MUMBAI || networkId === SupportedNetworkId.POLYGON
}

class MaticNativeCurrency extends NativeCurrency {
  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId
  }

  get wrapped(): Token {
    if (!isMatic(this.chainId)) throw new Error('Not matic')
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId]
    invariant(wrapped instanceof Token)
    return wrapped
  }

  public constructor(networkId: number) {
    if (!isMatic(networkId)) throw new Error('Not matic')
    super(networkId, 18, 'MATIC', 'Polygon Matic')
  }
}

export class ExtendedEther extends Ether {
  public get wrapped(): Token {
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId]
    if (wrapped) return wrapped
    throw new Error('Unsupported chain ID')
  }

  private static _cachedExtendedEther: { [networkId: number]: NativeCurrency } = {}

  public static onChain(networkId: number): ExtendedEther {
    return this._cachedExtendedEther[networkId] ?? (this._cachedExtendedEther[networkId] = new ExtendedEther(networkId))
  }
}

const cachedNativeCurrency: { [networkId: number]: NativeCurrency } = {}
export function nativeOnChain(networkId: number): NativeCurrency {
  return (
    cachedNativeCurrency[networkId] ??
    (cachedNativeCurrency[networkId] = isMatic(networkId)
      ? new MaticNativeCurrency(networkId)
      : ExtendedEther.onChain(networkId))
  )
}

export const TOKEN_SHORTHANDS: { [shorthand: string]: { [networkId in SupportedNetworkId]?: string } } = {
  USDC: {
    [SupportedNetworkId.MAINNET]: USDC_MAINNET.address,
    [SupportedNetworkId.POLYGON]: USDC_POLYGON.address,
    [SupportedNetworkId.POLYGON_MUMBAI]: USDC_POLYGON_MUMBAI.address,
    [SupportedNetworkId.GOERLI]: USDC_GÖRLI.address,
    [SupportedNetworkId.RINKEBY]: USDC_RINKEBY.address,
    [SupportedNetworkId.ROPSTEN]: USDC_ROPSTEN.address,
  },
}
