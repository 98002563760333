import { Currency, NativeCurrency, Token } from '@uniswap/sdk-core'
import CurrencyLogo from 'components/base/CurrencyLogo'
import { NETWORK_INFO, SupportedNetworkId } from 'constants/networks'
import useNativeCurrency from 'hooks/blockchain/useNativeCurrency'
import useDebounce from 'hooks/environment/useDebounce'
import { useAllTokens } from 'hooks/tokens/Tokens'
import { getTokenFilter } from 'hooks/tokens/useTokenList/filtering'
import { useSortTokensByQuery } from 'hooks/tokens/useTokenList/sorting'
import { Key, useMemo, useState } from 'react'
import { Text } from 'rebass'
import { useSetOutputNetworkId } from 'state/network/hook'
import styled from 'styled-components/macro'

import { Item, SearchAutocomplete } from './SearchAutocomplete'

const StyledBalanceRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const NetworkName = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: #575a62;
`

interface SearchProps {
  disableNonToken?: boolean
  onDismiss: () => void
  otherSelectedCurrency?: Currency | null
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  showCurrencyAmount?: boolean
}
const SearchBar = ({
  onCurrencySelect,
  disableNonToken,
  otherSelectedCurrency,
  selectedCurrency,
  showCurrencyAmount,
  onDismiss,
}: SearchProps) => {
  const [searchQuery, setSearchQuery] = useState<string>('')
  const allTokens = useAllTokens(true)
  const mainnetNativeToken = useNativeCurrency(SupportedNetworkId.MAINNET)
  const ropstenNativeToken = useNativeCurrency(SupportedNetworkId.ROPSTEN)
  const polygonNativeToken = useNativeCurrency(SupportedNetworkId.POLYGON)
  const mumbaiNativeToken = useNativeCurrency(SupportedNetworkId.POLYGON_MUMBAI)
  const natives = useMemo(() => {
    return [mainnetNativeToken, ropstenNativeToken, polygonNativeToken, mumbaiNativeToken]
  }, [mainnetNativeToken, mumbaiNativeToken, polygonNativeToken, ropstenNativeToken])
  const setOutputNetworkId = useSetOutputNetworkId()
  const debouncedQuery = useDebounce(searchQuery, 200)
  // const balances = useAllTokenBalances()
  const filteredTokens: Currency[] = useMemo(() => {
    if (Object.values(allTokens).length && natives.length) {
      return [...Object.values(allTokens), ...natives].filter(getTokenFilter(debouncedQuery))
    }
    return []
  }, [allTokens, natives, debouncedQuery])
  // const sortedTokens: Currency[] = useMemo(() => {
  //   return filteredTokens.sort(tokenComparator.bind(null, balances))
  // }, [balances, filteredTokens])
  const filteredSortedTokens = useSortTokensByQuery(debouncedQuery, filteredTokens)

  const onSelectionChange = (key: Key) => {
    if (!key) return

    const token = filteredSortedTokens.find((item) => {
      if (item.isToken && key === item.address) {
        return item
      } else if (item.isNative) {
        const keyValues = (key as string)?.split('-')
        if (keyValues.length === 2 && keyValues[0] === `${item.chainId}` && keyValues[1] === item.symbol) {
          return item
        }
      }
      return false
    })
    if (token) {
      setOutputNetworkId(token.chainId)
      onCurrencySelect(token)
      setSearchQuery('')
    }
  }

  return (
    <SearchAutocomplete
      items={filteredSortedTokens as unknown as Iterable<Record<string, unknown>>}
      inputValue={searchQuery}
      onSelectionChange={onSelectionChange}
      onInputChange={setSearchQuery}
      aria-label="Search token"
    >
      {(item) => (
        <Item
          key={
            `${
              (item as unknown as Currency).isNative
                ? `${(item as unknown as NativeCurrency).chainId}-${(item as unknown as NativeCurrency).symbol}`
                : (item as unknown as Token).address
            }` as Key
          }
        >
          <CurrencyLogo currency={item as unknown as Currency} size={'32px'} />
          <StyledBalanceRow>
            <Text title={(item as unknown as Currency).name} fontWeight={500} marginLeft={12} fontSize={14}>
              {(item as unknown as Currency).name}
            </Text>
            &nbsp;
          </StyledBalanceRow>
          <NetworkName>{NETWORK_INFO[(item as unknown as Currency).chainId].label}</NetworkName>
        </Item>
      )}
    </SearchAutocomplete>
  )
}

export default SearchBar
