import { NETWORK_IDS_TO_NAMES, SupportedNetworkId } from 'constants/networks'
import type { EthereumProvider } from 'lib/ethereum'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSwapState } from 'state/swap/hooks'
import { replaceURLParam } from 'utils/routes'
import { switchToNetwork } from 'utils/switchToNetwork'
import { useWeb3React } from 'web3-react-core'

import { injected } from '../../lib/connectors'
import { Field } from '../../state/swap/actions'
import EventLogger from '../../utils/eventLogger'
import { isMobile } from '../../utils/userAgent'

export function useEagerConnect() {
  const { activate, active, account, library, chainId } = useWeb3React()
  const [tried, setTried] = useState(false)
  const isDisconnected = localStorage.getItem('isDisconnected')
  const signable = localStorage.getItem('signable')
  const accessToken = localStorage.getItem('accessToken')

  const {
    [Field.INPUT]: { chainId: networkId },
  } = useSwapState()
  const historyRoute = useHistory()

  // then, if that fails, try connecting to an injected connector
  useEffect(() => {
    setTried(true)
    if (account) {
      // localStorage.removeItem('isDisconnected')
      localStorage.setItem('isDisconnected', 'false')
      if (isDisconnected === 'true' && networkId && chainId !== networkId) {
        switchToNetwork({ library, networkId: networkId as SupportedNetworkId })
          .then(() => {
            historyRoute.replace({
              search: replaceURLParam(
                historyRoute.location.search,
                'network',
                NETWORK_IDS_TO_NAMES[networkId as SupportedNetworkId] || ''
              ),
            })
          })
          .catch((error) => {
            console.error('Failed to switch networks', error)
          })
      }
      if ((isDisconnected === 'true' && signable) || (signable && !accessToken && !isDisconnected)) {
        const eventLogger = new EventLogger()
        eventLogger.sendAddress(account).then((data) => {
          eventLogger
            .signMessage(data)
            .then((data) => {
              eventLogger
                .authenticate(data)
                .then((data) => {
                  localStorage.removeItem('signable')
                  return data
                })
                .then((data) => localStorage.setItem('accessToken', data.accessToken))
            })
            .catch((err) => {
              localStorage.setItem('isDisconnected', 'true')
            })
          return data
        })
      }
    }
    if (!active && isDisconnected === 'false' && (accessToken || signable)) {
      injected.isAuthorized().then((isAuthorized) => {
        if (isAuthorized) {
          activate(injected, undefined, true).catch(() => {
            setTried(true)
          })
        } else {
          if (isMobile && window.ethereum) {
            activate(injected, undefined, true).catch(() => {
              setTried(true)
            })
          } else {
            setTried(true)
          }
        }
      })
    }
  }, [accessToken, account, activate, active, chainId, historyRoute, isDisconnected, library, networkId, signable])

  // wait until we get confirmation of a connection to flip the flag
  useEffect(() => {
    if (active) {
      setTried(true)
    }
  }, [active])

  return tried
}

/**
 * Use for network and injected - logs user in
 * and out after checking what network theyre on
 */
export function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3React()

  useEffect(() => {
    const ethereum = window.ethereum as EthereumProvider | undefined

    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = () => {
        // eat errors
        activate(injected, undefined, true).catch((error) => {
          console.error('Failed to activate after chain changed', error)
        })
      }

      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          // eat errors
          activate(injected, undefined, true).catch((error) => {
            console.error('Failed to activate after accounts changed', error)
          })
        }
      }

      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged)
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
        }
      }
    }
    return undefined
  }, [active, error, suppress, activate])
}
