import React from 'react'
import styled from 'styled-components/macro'

import { ReactComponent as ButtomLeft } from '../../../assets/svg/left.svg'
import { ReactComponent as ButtonLeftDisable } from '../../../assets/svg/left_disable.svg'
import { ReactComponent as ButtonRight } from '../../../assets/svg/right.svg'
import { ReactComponent as ButtonRightDisable } from '../../../assets/svg/right_disable.svg'
const WrapButtonPagination = styled.button`
  background: none;
  border: none;
  margin: 4px;
`
const WrapperPagination = styled.div`
  position: sticky;
  margin-top: 20px;
  bottom: 0px;
`
const PageInfo = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
`
const PageNumber = styled.span`
  color: ${({ theme }) => theme.green1};
  font-weight: 600;
`

interface PaginationProps {
  currentPage: number
  totalPages: number | undefined
  handleNextPage: (page: number) => void
  handlePrevPage: (page: number) => void
}
const Pagination = ({ currentPage, totalPages, handlePrevPage, handleNextPage }: PaginationProps) => {
  return (
    <WrapperPagination>
      <PageInfo>
        Page <PageNumber>{currentPage}</PageNumber> of <PageNumber>{totalPages}</PageNumber>
      </PageInfo>
      <WrapButtonPagination onClick={() => handlePrevPage(currentPage)} disabled={currentPage === 1}>
        {currentPage === 1 ? <ButtonLeftDisable /> : <ButtomLeft />}
      </WrapButtonPagination>
      <WrapButtonPagination onClick={() => handleNextPage(currentPage)} disabled={currentPage === totalPages}>
        {currentPage === totalPages ? <ButtonRightDisable /> : <ButtonRight />}
      </WrapButtonPagination>
    </WrapperPagination>
  )
}

export default Pagination
