import Loader from 'components/base/Loader'
import ApeModeQueryParamReader from 'hooks/environment/useApeModeQueryParamReader'
import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components/macro'
import { updateAppVersion } from 'utils/updateAppVersion'

import Web3ReactManager from '../components/account/Web3ReactManager'
import ErrorBoundary from '../components/base/ErrorBoundary'
// import Popups from '../components/base/Popups'
import Header from '../components/layout/Header'
import Sidebar from '../components/layout/Sidebar'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
//import Swap from './Swap'
const Swap = React.lazy(() => import('./Swap'))
const Portfolio = React.lazy(() => import('./Portfolio'))
const Dashboard = React.lazy(() => import('./Dashboard'))
const Trade = React.lazy(() => import('./Trade'))

const AppWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  /* margin-top: 0rem; */
`

const BodyWrapper = styled.div`
  height: 100%;
  position: relative;
  flex-direction: column;
  padding: 0 1.5rem 1.5rem 0.5rem;
  flex: 1;
  z-index: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 4rem 8px 16px 8px;
  `};
`

const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  padding-top: 1.5rem;
  background: ${({ theme }) => theme.bg7};
  z-index: 200;
  width: 100%;
  box-shadow: -15px -15px ${({ theme }) => theme.bg7}, 40px 5px ${({ theme }) => theme.bg7};
`
export default function App() {
  updateAppVersion()
  return (
    <>
      <ErrorBoundary>
        <Route component={DarkModeQueryParamReader} />
        <Route component={ApeModeQueryParamReader} />
        <Web3ReactManager>
          <AppWrapper>
            <Sidebar />
            <BodyWrapper>
              {/* <Popups /> */}
              <HeaderWrapper>
                <Header />
              </HeaderWrapper>
              <div style={{ flex: 1 }}>
                <Suspense fallback={<Loader />}>
                  <Switch>
                    <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
                    <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                    <Route exact strict path="/dashboard" component={Dashboard} />
                    <Route exact strict path="/swap" component={Trade} />
                    <Route exact strict path="/portfolio" component={Portfolio} />
                    <Route component={RedirectPathToSwapOnly} />
                  </Switch>
                </Suspense>
              </div>
            </BodyWrapper>
          </AppWrapper>
        </Web3ReactManager>
      </ErrorBoundary>
    </>
  )
}
