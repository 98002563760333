import { FACTORY_ADDRESS as V2_FACTORY_ADDRESS } from '@uniswap/v2-sdk'
import { FACTORY_ADDRESS as V3_FACTORY_ADDRESS } from '@uniswap/v3-sdk'

import { constructSameAddressMap } from '../utils/constructSameAddressMap'
import { CHAIN_LIST } from './chainlist'
import { SupportedNetworkId } from './networks'

type AddressMap = { [networkId: number]: string }

export const UNI_ADDRESS: AddressMap = constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984')
export const MULTICALL_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x1F98415757620B543A52E61c46B32eB19261F984', [
    SupportedNetworkId.POLYGON_MUMBAI,
    SupportedNetworkId.POLYGON,
  ]),
}
export const V2_FACTORY_ADDRESSES: AddressMap = constructSameAddressMap(V2_FACTORY_ADDRESS)

export const V2_ROUTER_ADDRESS: AddressMap = constructSameAddressMap('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D')
export const V3_ROUTER_ADDRESS: AddressMap = constructSameAddressMap('0xE592427A0AEce92De3Edee1F18E0157C05861564', [
  SupportedNetworkId.POLYGON,
  SupportedNetworkId.POLYGON_MUMBAI,
])
export const SWAP_ROUTER_ADDRESSES: AddressMap = constructSameAddressMap('0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45', [
  SupportedNetworkId.POLYGON,
  SupportedNetworkId.POLYGON_MUMBAI,
])

export const V3_CORE_FACTORY_ADDRESSES: AddressMap = constructSameAddressMap(V3_FACTORY_ADDRESS, [
  SupportedNetworkId.POLYGON_MUMBAI,
  SupportedNetworkId.POLYGON,
])
export const QUOTER_ADDRESSES: AddressMap = constructSameAddressMap('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6', [
  SupportedNetworkId.POLYGON_MUMBAI,
  SupportedNetworkId.POLYGON,
])
export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = constructSameAddressMap(
  '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
  [SupportedNetworkId.POLYGON_MUMBAI, SupportedNetworkId.POLYGON]
)
export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedNetworkId.MAINNET]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedNetworkId.ROPSTEN]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedNetworkId.GOERLI]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedNetworkId.RINKEBY]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
}

export const V3_MIGRATOR_ADDRESSES: AddressMap = constructSameAddressMap('0xA5644E29708357803b5A882D272c41cC0dF92B34', [
  SupportedNetworkId.POLYGON_MUMBAI,
  SupportedNetworkId.POLYGON,
])
export const DEFAULT_NETWORK_ADDRESS = {
  [CHAIN_LIST.ethereum]: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  [CHAIN_LIST['polygon-pos']]: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
  [CHAIN_LIST.avalanche]: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
  [CHAIN_LIST['optimistic-ethereum']]: '0x4200000000000000000000000000000000000006',
}
