import { Trans } from '@lingui/macro'
import Modal from 'components/base/Modal'
import { useContext } from 'react'
import { Text } from 'rebass'
import { useToogleTransactionRefund } from 'state/application/hooks'
// import { ApplicationModal } from 'state/application/reducer'
import styled, { ThemeContext } from 'styled-components/macro'
import { CloseIcon } from 'theme'

import { ReactComponent as RefundingIcon } from '../../../../assets/svg/refund.svg'
import { ButtonPrimary } from '../../../base/Button'
import { AutoColumn } from '../../Column'
import { RowBetween } from '../../Row'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.bg0};
  width: 480px;
  height: 480px;
  z-index: 150;
  top: 10rem;
  position: absolute;
  border-radius: 16px;
  box-shadow: 1px 1px 10px rgba(10, 4, 39, 0.05);
`

const Section = styled(AutoColumn)<{ inline?: boolean }>`
  padding: ${({ inline }) => (inline ? '0' : '0')};
`
const WrapCloseIcon = styled.div`
  margin-top: 20px;
  margin-right: 20px;
`
const DescriptionRefund = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
`

const RequestRefundModal = ({
  isOpen,
  onDismiss,
  onConfirm,
  requesting,
}: {
  isOpen: any
  onDismiss: any
  onConfirm: any
  requesting: boolean
}) => {
  const toggleTransactionRefund = useToogleTransactionRefund()
  const theme = useContext(ThemeContext)
  return (
    <>
      <Modal isOpen={isOpen} onDismiss={onDismiss} maxWidth={480}>
        <Wrapper>
          <Section inline={true}>
            <RowBetween>
              <div />
              <WrapCloseIcon>
                <CloseIcon onClick={toggleTransactionRefund} />
              </WrapCloseIcon>
            </RowBetween>
            <AutoColumn
              gap="64px"
              justify={'center'}
              style={{
                padding: '0 24px 24px',
              }}
            >
              <Text fontWeight={500} fontSize={20} lineHeight={'28px'} textAlign="center">
                <Trans>Requesting Refund Transaction</Trans>
                <DescriptionRefund>Requesting Refund Transaction</DescriptionRefund>
              </Text>
              <RefundingIcon />
              {/* <ColumnCenter> */}
              <ButtonPrimary
                onClick={() => {
                  onConfirm()
                  onDismiss()
                }}
              >
                <Text fontSize={20} fontWeight={500}>
                  <Trans>Request</Trans>
                </Text>
              </ButtonPrimary>
              {/* </ColumnCenter> */}
            </AutoColumn>
          </Section>
        </Wrapper>
      </Modal>
    </>
  )
}

export default RequestRefundModal
