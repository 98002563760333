import { SupportedNetworkId } from '../constants/networks'

const DEFAULT_NETWORKS = [
  SupportedNetworkId.MAINNET,
  SupportedNetworkId.ROPSTEN,
  SupportedNetworkId.RINKEBY,
  SupportedNetworkId.GOERLI,
]

export function constructSameAddressMap<T extends string>(
  address: T,
  additionalNetworks: SupportedNetworkId[] = []
): { [networkId: number]: T } {
  return DEFAULT_NETWORKS.concat(additionalNetworks).reduce<{ [networkId: number]: T }>((memo, networkId) => {
    memo[networkId] = address
    return memo
  }, {})
}
