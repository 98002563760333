import { Currency, Token } from '@uniswap/sdk-core'
import useActiveWeb3 from 'hooks/blockchain/useActiveWeb3'
import { useCurrencyFromMap, useTokenFromMapOrNetwork } from 'hooks/tokens/useCurrency'
import { getTokenFilter } from 'hooks/tokens/useTokenList/filtering'
import { useMemo } from 'react'
import { useAllLists, useCombinedActiveList, useInactiveListUrls } from 'state/lists/hooks'
import { TokenAddressMap, useUnsupportedTokenList } from 'state/lists/hooks'
import { WrappedTokenInfo } from 'state/lists/wrappedTokenInfo'
import { useUserAddedTokens } from 'state/user/hooks'

// reduce token map into standard address <-> Token mapping, optionally include user added tokens
function useTokensFromMap(
  tokenMap: TokenAddressMap,
  includeUserAdded: boolean,
  isAllTokens?: boolean,
  chainId?: number
): { [address: string]: Token } {
  const { networkId } = useActiveWeb3()
  const userAddedTokens = useUserAddedTokens()
  const network = chainId || networkId

  return useMemo(() => {
    if (!network) return {}
    let mapWithoutUrls = {}

    if (isAllTokens) {
      const networks = Object.keys(tokenMap)
      networks.forEach((network) => {
        Object.keys(tokenMap[Number(network)] ?? {}).reduce<{ [address: string]: Token }>((newMap, address) => {
          newMap[address] = tokenMap[Number(network)][address].token
          return Object.assign(mapWithoutUrls, newMap)
        }, {})
      })
    } else {
      // reduce to just tokens
      mapWithoutUrls = Object.keys(tokenMap[network] ?? {}).reduce<{ [address: string]: Token }>((newMap, address) => {
        newMap[address] = tokenMap[network][address].token
        return newMap
      }, {})
    }

    if (includeUserAdded) {
      return (
        userAddedTokens
          // reduce into all ALL_TOKENS filtered by the current chain
          .reduce<{ [address: string]: Token }>(
            (tokenMap, token) => {
              tokenMap[token.address] = token
              return tokenMap
            },
            // must make a copy because reduce modifies the map, and we do not
            // want to make a copy in every iteration
            { ...mapWithoutUrls }
          )
      )
    }

    return mapWithoutUrls
  }, [network, isAllTokens, includeUserAdded, tokenMap, userAddedTokens])
}

export function useAllTokens(isAllToken?: boolean, chainId?: number): { [address: string]: Token } {
  const allTokens = useCombinedActiveList()
  return useTokensFromMap(allTokens, true, isAllToken, chainId)
}

export function useUnsupportedTokens(): { [address: string]: Token } {
  const unsupportedTokensMap = useUnsupportedTokenList()
  const unsupportedTokens = useTokensFromMap(unsupportedTokensMap, false)

  return { ...unsupportedTokens }
}

export function useSearchInactiveTokenLists(search: string | undefined, minResults = 10): WrappedTokenInfo[] {
  const lists = useAllLists()
  const inactiveUrls = useInactiveListUrls()
  const { networkId } = useActiveWeb3()
  const activeTokens = useAllTokens()
  return useMemo(() => {
    if (!search || search.trim().length === 0) return []
    const tokenFilter = getTokenFilter(search)
    const result: WrappedTokenInfo[] = []
    const addressSet: { [address: string]: true } = {}
    for (const url of inactiveUrls) {
      const list = lists[url].current
      if (!list) continue
      for (const tokenInfo of list.tokens) {
        if (tokenInfo.chainId === networkId && tokenFilter(tokenInfo)) {
          const wrapped: WrappedTokenInfo = new WrappedTokenInfo(tokenInfo, list)
          if (!(wrapped.address in activeTokens) && !addressSet[wrapped.address]) {
            addressSet[wrapped.address] = true
            result.push(wrapped)
            if (result.length >= minResults) return result
          }
        }
      }
    }
    return result
  }, [activeTokens, networkId, inactiveUrls, lists, minResults, search])
}

export function useIsTokenActive(token: Token | undefined | null): boolean {
  const activeTokens = useAllTokens()

  if (!activeTokens || !token) {
    return false
  }

  return !!activeTokens[token.address]
}

// Check if currency is included in custom list from user storage
export function useIsUserAddedToken(currency: Currency | undefined | null): boolean {
  const userAddedTokens = useUserAddedTokens()

  if (!currency) {
    return false
  }

  return !!userAddedTokens.find((token) => currency.equals(token))
}

// undefined if invalid or does not exist
// null if loading or null was passed
// otherwise returns the token
export function useToken(tokenAddress?: string | null): Token | null | undefined {
  const tokens = useAllTokens()
  return useTokenFromMapOrNetwork(tokens, tokenAddress)
}

export function useCurrency(currencyId?: string | null, chainId?: number): Currency | null | undefined {
  const tokens = useAllTokens(true)
  return useCurrencyFromMap(tokens, currencyId, chainId)
}
