import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'

interface GasPrice extends Record<string, string> {
  SafeGasPrice: string
  ProposeGasPrice: string
  FastGasPrice: string
}

export enum GasPriceListTier {
  Fast = 'Fast',
  Normal = 'Normal',
  Slow = 'Slow',
}

export type GasPriceList = Record<GasPriceListTier, string>

const endpoint = `https://api.etherscan.io/api`
const apiKey = process.env.REACT_APP_ETHERSCAN_API_KEY

export function useFetchEstimatedGasPriceList() {
  const [gasPrice, setGasPrice] = useState<GasPrice>()
  const [err, setError] = useState()

  const fetchEstimatedGasPriceList = async () => {
    await axios
      .get(endpoint + `?module=gastracker&action=gasoracle&apikey=${apiKey}`)
      .then((response) => {
        const { SafeGasPrice, ProposeGasPrice, FastGasPrice } = response.data.result
        setGasPrice({ SafeGasPrice, ProposeGasPrice, FastGasPrice })
      })
      .catch((err) => {
        setError(err)
      })
  }

  useEffect(() => {
    fetchEstimatedGasPriceList()
  }, [])

  useEffect(() => {
    let timeout: any
    if (gasPrice) {
      timeout = setTimeout(() => {
        fetchEstimatedGasPriceList()
      }, 15000)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [gasPrice])

  const gasFee: GasPriceList | undefined = useMemo(
    () =>
      gasPrice
        ? {
            [GasPriceListTier.Fast]: gasPrice?.FastGasPrice,
            [GasPriceListTier.Normal]: gasPrice?.ProposeGasPrice,
            [GasPriceListTier.Slow]: gasPrice?.SafeGasPrice,
          }
        : undefined,
    [gasPrice]
  )

  return { gasFee, err }
}
