import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const transactionsHistoryApi = createApi({
  reducerPath: 'transactionsHistory',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: (headers) => {
      const accessToken = localStorage.getItem('accessToken')
      headers.set('Authorization', `Bearer ${accessToken}`)
      return headers
    },
  }),
  endpoints: (builder) => ({
    getTransactionsHistory: builder.query({
      query: (args) => {
        const { page, numberOfRecordsPerPage, type } = args
        return {
          url: 'transaction/',
          params: { page, numberOfRecordsPerPage, type },
        }
      },
    }),
  }),
})

export const { useGetTransactionsHistoryQuery } = transactionsHistoryApi
