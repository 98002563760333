import { createAction, createReducer } from '@reduxjs/toolkit'

export const setOutputNetworkId = createAction<number>('setOutputNetworkId')
const initialState = 0
export default createReducer(initialState, (builder) => {
  builder.addCase(setOutputNetworkId, (state, action) => {
    return (state = action.payload)
  })
  // You can chain calls, or have separate `builder.addCase()` lines each time
})
