import { NativeCurrency } from '@uniswap/sdk-core'
import { SupportedNetworkId } from 'constants/networks'
import { nativeOnChain } from 'constants/tokens'
import useActiveWeb3 from 'hooks/blockchain/useActiveWeb3'
import { useMemo } from 'react'

export default function useNativeCurrency(chainId?: number): NativeCurrency {
  const { networkId } = useActiveWeb3()
  return useMemo(
    () =>
      networkId
        ? nativeOnChain(chainId || networkId)
        : // display mainnet when not connected
          nativeOnChain(SupportedNetworkId.MAINNET),
    [chainId, networkId]
  )
}
