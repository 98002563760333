export interface ReponseTransactionStatusType {
  transactionHash: string
  networkSwap: string
  networkSwapIcon: string
  tokenSwap: string
  tokenSwapIcon: string
  tokenSwapDecimals: number
  tokenSwapSymbol: string
  networkReceivedIcon: string
  tokenReceived: string
  tokenReceivedSymbol: string
  tokenReceivedIcon: string
  tokenReceivedDecimals: number
  amountSwap: number
  amountReceived: number
  account: string
  swapTime: string
  type: string
  status: string
  gasFee: number
  gasLimit: number
  gasPrice: number
  exchangeRate: number
  bridgeStatus?: string
  transferId?: string
  networkSwapId: number
  networkReceivedId: number
  confirmedRefundStatus?: string
  meta: {
    [key: string]: string
  }
}
export enum TransMetaData {
  EXCHANGE_RATE = 'exchangeRate',
  PROVIDER = 'provider',
  SLIPPAGE = 'slippage',
  ROUTE = 'route',
  MINIMUM_RECEIVE = 'mininumReceive',
}

export interface ReponseTransactionStatus {
  result: {
    data: ReponseTransactionStatusType[]
    totalPage: number
  }
}
