import { atomWithImmer } from 'jotai/immer'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { useCallback } from 'react'

import useActiveWeb3 from './useActiveWeb3'
import useBlockNumber from './useBlockNumber'

// The oldest block (per chain) to be considered valid.
const oldestBlockMapAtom = atomWithImmer<{ [networkId: number]: number }>({})

const DEFAULT_MAX_BLOCK_AGE = 10

export function useSetOldestValidBlock(): (block: number) => void {
  const { networkId } = useActiveWeb3()
  const updateValidBlock = useUpdateAtom(oldestBlockMapAtom)
  return useCallback(
    (block: number) => {
      if (!networkId) return
      updateValidBlock((oldestBlockMap) => {
        oldestBlockMap[networkId] = Math.max(block, oldestBlockMap[networkId] || 0)
      })
    },
    [networkId, updateValidBlock]
  )
}

export function useGetIsValidBlock(maxBlockAge = DEFAULT_MAX_BLOCK_AGE): (block: number) => boolean {
  const { networkId } = useActiveWeb3()
  const currentBlock = useBlockNumber()
  const oldestBlockMap = useAtomValue(oldestBlockMapAtom)
  const oldestBlock = networkId ? oldestBlockMap[networkId] : 0
  return useCallback(
    (block: number) => {
      if (!currentBlock) return false
      if (currentBlock - block > maxBlockAge) return false
      return currentBlock >= oldestBlock
    },
    [currentBlock, maxBlockAge, oldestBlock]
  )
}

export default function useIsValidBlock(block: number): boolean {
  return useGetIsValidBlock()(block)
}
