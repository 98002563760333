import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import moment from 'moment'
const apiEndpoint = 'https://api.coingecko.com/api/v3'
export const priceTokenApi = createApi({
  reducerPath: 'priceTokenApi',
  baseQuery: fetchBaseQuery({ baseUrl: apiEndpoint }),
  endpoints: (builder) => ({
    getPriceToken: builder.query({
      query: ({
        address,
        timestamp,
        platform,
      }: {
        address: string
        timestamp: number
        platform: string | undefined
      }) => {
        return `/coins/${platform}/contract/${address}/market_chart/range?vs_currency=usd&from=${timestamp}&to=${moment().unix()}`
      },
      extraOptions: {
        maxRetries: 0,
      },
    }),
  }),
})

//RTK Query will automatically generate hooks for each endpoint query
export const { useGetPriceTokenQuery } = priceTokenApi
