// eslint-disable-next-line no-restricted-imports
import ms from 'ms.macro'
import { useContext, useMemo, useState } from 'react'
import { HashLoader } from 'react-spinners'
import { useGetTransactionStatusQuery } from 'state/transactionStatus/slice'
import styled, { ThemeContext } from 'styled-components/macro'

import { ButtonPrimary } from '../Button'
import { baseUrl } from '../ListAllTransaction'
import { WrapIconLoading, WrapNotify, WrapperPagination, WrapTransaction } from '../ListSwapTransaction'
import Pagination from '../Pagination'
import TransStatusCrossChain from './TransStatusCrossChain'

export const AmountValueSell = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.red4};
  margin-top: -17px;
`
export const AmountValueBuy = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.green5};
  margin-top: -17px;
`
export const StatusTransaction = styled.p`
  color: ${({ theme }) => theme.red4};
  font-size: 14px;
`
export const TimeTransaction = styled.p`
  color: ${({ theme }) => theme.timetransaction};
  margin-top: -15px;
  font-size: 12px;
`
export const TransactionDetails = styled.div`
  margin-top: 20px; ;
`

export const WrapperCrossChain = styled.div<{ open?: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ open }) => (open ? '261px' : '124px')};
  justify-content: space-between;
  align-items: center;
  width: 587px;
  border-radius: 12px;
  border: ${({ open }) => (open ? '1px solid rgba(205, 208, 215, 0.4)' : 'none')};
  box-shadow: 10px 24px 54px rgba(0, 0, 0, 0.06), 0px 0px 5px rgba(7, 44, 33, 0.05);
  background: ${({ theme }) => theme.bg0};
  transition: 0.5s;
  overflow: hidden;
`

export const ButtonStatus = styled(ButtonPrimary)`
  height: 34px;
  width: fit-content;
  background-color: ${({ theme }) => theme.green1};
  border-radius: 4px;
  margin: auto;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.text5};
`
const ListCrossChainTransaction = () => {
  const theme = useContext(ThemeContext)
  const [page, setPage] = useState(1)
  const [clickedIndex, setClickedIndex] = useState<any>({})
  const {
    data: valueTransaction,
    isLoading,
    error,
  } = useGetTransactionStatusQuery(
    { typeTransaction: 'cross_chain', pageNumber: page || 1, url: baseUrl },
    {
      pollingInterval: ms`30s`,
      refetchOnMountOrArgChange: true,
    }
  )
  const transactions = useMemo(() => valueTransaction, [valueTransaction])

  const handlePrevPage = (prevPage: number) => {
    setPage((prevPage) => prevPage - 1)
  }
  const handleNextPage = (nextPage: number) => {
    setPage((nextPage) => nextPage + 1)
  }
  const handleClick = (index: number, transactionHash: string) => () => {
    setClickedIndex((state: any) => ({
      ...state,
      [index]: !state[index],
    }))
  }

  return (
    <>
      <WrapTransaction>
        {isLoading || error ? (
          <WrapIconLoading>
            <HashLoader size={35} color={theme.blue1} />
          </WrapIconLoading>
        ) : !valueTransaction?.result.data.length ? (
          <WrapNotify>No transaction history!</WrapNotify>
        ) : (
          <>
            <TransStatusCrossChain
              transactions={transactions}
              handleOnClick={handleClick}
              clickedIndex={clickedIndex}
              setPage={setPage}
            />
          </>
        )}
      </WrapTransaction>
      {valueTransaction?.result.totalPage !== 0 && (
        <WrapperPagination>
          <div></div>
          <Pagination
            totalPages={valueTransaction?.result.totalPage}
            currentPage={page}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
          />
        </WrapperPagination>
      )}
    </>
  )
}
export default ListCrossChainTransaction
