import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from 'web3-react-core'

import { NetworkContextName } from '../../constants/misc'

export default function useActiveWeb3() {
  const interfaceContext = useWeb3React<Web3Provider>()
  const interfaceNetworkContext = useWeb3React<Web3Provider>(NetworkContextName)

  if (interfaceContext.active) {
    return { networkId: interfaceContext.chainId, ...interfaceContext }
  }

  return { networkId: interfaceNetworkContext.chainId, ...interfaceNetworkContext }
}
