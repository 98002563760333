import { Trans } from '@lingui/macro'
import { ButtonPrimary } from 'components/base/Button'
import { TransactionDetail, TransType } from 'components/base/ListAllTransaction'
import ConfirmRefundModal from 'components/base/ListCrossChainTransaction/ConfirmRefundModal'
import RequestRefundModal from 'components/base/ListCrossChainTransaction/RequestRefund'
import { NETWORK_INFO } from 'constants/networks'
// eslint-disable-next-line no-restricted-imports
import { ethers } from 'ethers'
import { base64, getAddress, hexlify } from 'ethers/lib/utils'
import useActiveWeb3 from 'hooks/blockchain/useActiveWeb3'
import { capitalizeFirstLetter } from 'hooks/swap/useGetTransactionTitleAndLabel'
import moment from 'moment'
import { useRef } from 'react'
import { SyntheticEvent, useContext, useState } from 'react'
import { Text } from 'rebass'
import { BridgeStatus } from 'state/transactions/actions'
import { ReponseTransactionStatus, ReponseTransactionStatusType } from 'state/transactionStatus/type'
import styled, { ThemeContext } from 'styled-components/macro'
import confirmedRefund, { RequestedRefundType } from 'utils/confirmedRefund'
import getTransferStatus from 'utils/getTransferStatus'
import requestRefund from 'utils/requestRefund'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'

import { ReactComponent as ArrowUpRight } from '../../../assets/svg/arrow-up-right-circle.svg'
import { ReactComponent as SwapIcon } from '../../../assets/svg/tokentransaction.svg'
import bridgeAbi from '../../../lib/abi/bridge.json'
import {
  ContentTransaction,
  LogoNetWork,
  NetworkName,
  StatusTransaction,
  TimeTransaction,
  WrapperContent,
} from '../ListSwapTransaction/TransStatusSwap'
import Loader from '../Loader'

export const ButtonStatus = styled(ButtonPrimary)`
  height: 34px;
  width: fit-content;
  background-color: ${({ theme }) => theme.green1};
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  margin-left: auto;
  text-align: center;
  color: ${({ theme }) => theme.text5};
`
const ListTransaction = styled.div<{ open?: boolean }>`
  display: flex;
  padding: 0px 20px 6px 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const Swap = styled(SwapIcon)`
  width: 15px;
  height: 15px;
  margin-left: -2rem;
  margin-right: -2rem;
`

const AmountValueSell = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.red4};
  margin-top: -17px;
`
const AmountValueBuy = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.green5};
  margin-top: -17px;
`

export const TransactionDetails = styled.div`
  margin-top: 20px; ;
`

const WrapperCrossChain = styled.div<{ open?: boolean; hasButton?: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ open, hasButton }) => (open ? '260px' : hasButton ? '120px' : '95px')};
  justify-content: space-between;
  align-items: center;
  width: 587px;
  border-radius: 12px;
  border: ${({ open }) => (open ? '1px solid rgba(205, 208, 215, 0.4)' : 'none')};
  box-shadow: 10px 24px 54px rgba(0, 0, 0, 0.06), 0px 0px 5px rgba(7, 44, 33, 0.05);
  background: ${({ theme }) => theme.bg0};
  transition: 0.5s;
  overflow: hidden;
`

const TransStatusCrossChain = ({
  transactions,
  handleOnClick,
  clickedIndex,
  setPage,
}: {
  transactions?: ReponseTransactionStatus
  handleOnClick?: any
  clickedIndex?: any
  setPage?: any
}) => {
  const theme = useContext(ThemeContext)
  const [confirmRefundModal, setConfirmRefundModal] = useState(false)
  const [requestRefundModal, setRequestRefundModal] = useState(false)

  const requesting = useRef<boolean>(false)
  const confirming = useRef<boolean>(false)

  const { account, networkId } = useActiveWeb3()
  const web3 = new Web3(Web3.givenProvider || 'ws://localhost:8545')

  const handleRequestRefund = async (item: ReponseTransactionStatusType) => {
    // event.stopPropagation()
    setRequestRefundModal(true)
    if (!item.transferId || !account || !web3) {
      return
    }
    const transferId = item?.transferId
    if (transferId) {
      setRequestRefundModal(false)
      requesting.current = false
      await requestRefund({ transferId })
      await confirmedRefund({ transferId, confirmedRefundStatus: RequestedRefundType.REQUESTED })

      setPage()
      return
    }
  }

  const handleConfirmRefund = async (item: ReponseTransactionStatusType) => {
    if (!item.transferId || !account || !web3) return
    const { bridgeSmartContract: contractAddress } = NETWORK_INFO[Number(item.networkSwapId)]
    const providerContract = new web3.eth.Contract(bridgeAbi as AbiItem[], contractAddress, { from: account as string })
    // try {
    const response: any = await getTransferStatus({
      transferId: item.transferId,
      networkId: Number(item.networkSwapId),
    })
    const res = response.data

    const wdmsg = hexlify(base64.decode(res.wd_onchain))

    const signers = res.signers.map((item: any) => {
      const decodeSigners = base64.decode(item)
      const hexlifyObj = hexlify(decodeSigners)
      return getAddress(hexlifyObj)
    })

    const sigs = res.sorted_sigs.map((item: any) => {
      return hexlify(base64.decode(item))
    })

    const powers = res.powers.map((item: any) => {
      return ethers.BigNumber.from(base64.decode(item))
    })

    await providerContract.methods
      .withdraw(wdmsg, sigs, signers, powers)
      .send({ from: account as string })
      .on('transactionHash', async () => {
        await confirmedRefund({
          transferId: item.transferId as string,
          confirmedRefundStatus: RequestedRefundType.CONFIRMED,
        })
        setConfirmRefundModal(false)
        confirming.current = true
      })

    setPage()
    // } catch (error) {}

    return
  }
  const confirmRefundContent = (item: ReponseTransactionStatusType) => {
    if (item.bridgeStatus !== BridgeStatus.TRANSFER_REFUND_TO_BE_CONFIRMED) return <></>
    confirming.current = false

    if (item.networkSwapId !== networkId) {
      const { label } = NETWORK_INFO[item.networkSwapId]
      return (
        <>
          {/* <ButtonStatus disabled>Confirm Refund</ButtonStatus> */}
          <Text fontWeight={400} fontSize={12} lineHeight={'28px'} textAlign="right" color={theme.red2}>
            <Trans>Please switch to {label} network</Trans>
          </Text>
        </>
      )
    } else if (item.confirmedRefundStatus === RequestedRefundType.CONFIRMED) {
      // confirming.current = true
      return (
        <ButtonStatus disabled>
          <Loader size="12px" color={theme.text9} style={{ marginRight: '4px' }} /> Confirm Refund
        </ButtonStatus>
      )
    } else if (item.confirmedRefundStatus === RequestedRefundType.REQUESTED) {
      return (
        <ButtonStatus
          onClick={(event: SyntheticEvent) => {
            event.stopPropagation()
            setConfirmRefundModal(true)
          }}
          disabled={confirming.current ? true : false}
        >
          {confirming.current && <Loader size="12px" color={theme.text9} style={{ marginRight: '4px' }} />}
          Confirm Refund
        </ButtonStatus>
      )
    }
    return <></>
  }

  const requestRefundContent = (item: ReponseTransactionStatusType) => {
    if (item.bridgeStatus !== BridgeStatus.TRANSFER_TO_BE_REFUNDED) return <></>
    requesting.current = false
    if (item.networkSwapId !== networkId) {
      const { label } = NETWORK_INFO[item.networkSwapId]
      return (
        <>
          {/* <ButtonStatus disabled>Request Refund</ButtonStatus> */}
          <Text fontWeight={400} fontSize={12} lineHeight={'28px'} textAlign="right" color={theme.red2}>
            <Trans>Please switch to {label} network</Trans>
          </Text>
        </>
      )
    } else if (item.confirmedRefundStatus === RequestedRefundType.REQUESTED) {
      return (
        <ButtonStatus disabled>
          <Loader size="12px" color={theme.text9} style={{ marginRight: '4px' }} /> Request Refund
        </ButtonStatus>
      )
    } else if (!item.confirmedRefundStatus) {
      return (
        <ButtonStatus
          onClick={(event: SyntheticEvent) => {
            event.stopPropagation()
            setRequestRefundModal(true)
          }}
          disabled={requesting.current}
        >
          {requesting.current && <Loader size="12px" color={theme.text9} style={{ marginRight: '4px' }} />}
          Request Refund
        </ButtonStatus>
      )
    }
    return <></>
  }
  const isTransferSuccess = (item: ReponseTransactionStatusType) => {
    return item.bridgeStatus &&
      (item.bridgeStatus === BridgeStatus.TRANSFER_COMPLETED.toString() ||
        item.bridgeStatus === BridgeStatus.TRANSFER_REFUNDED.toString())
      ? true
      : false
  }
  const hasButton = (bridgeStatus: string) => {
    return bridgeStatus === BridgeStatus.TRANSFER_TO_BE_REFUNDED ||
      bridgeStatus === BridgeStatus.TRANSFER_REFUND_TO_BE_CONFIRMED
      ? true
      : false
  }

  return (
    <div>
      {transactions?.result.data
        .filter((item: ReponseTransactionStatusType) => item.type === TransType.BRIDGE)
        .map((item: ReponseTransactionStatusType, key: any) => (
          <WrapperCrossChain
            onClick={handleOnClick(key, item.transactionHash)}
            open={clickedIndex[key]}
            key={item.transactionHash}
            hasButton={hasButton(item.bridgeStatus || BridgeStatus.TRANSFER_UNKNOWN)}
          >
            <ListTransaction>
              <ContentTransaction>
                <LogoNetWork src={NETWORK_INFO[item.networkSwapId].logoUrl} />
                <WrapperContent>
                  <NetworkName>{item.networkSwap}</NetworkName>
                  <AmountValueSell>
                    -{Number(item.amountSwap / Math.pow(10, item.tokenSwapDecimals)).toFixed(4)} {item.tokenSwapSymbol}
                  </AmountValueSell>
                </WrapperContent>
              </ContentTransaction>
              <Swap />
              <ContentTransaction>
                <LogoNetWork src={NETWORK_INFO[item.networkReceivedId].logoUrl} />
                <WrapperContent>
                  <NetworkName>{item.tokenReceived}</NetworkName>
                  <AmountValueBuy>
                    +{Number(item.amountReceived / Math.pow(10, item.tokenReceivedDecimals)).toFixed(4)}{' '}
                    {item.tokenReceivedSymbol}
                  </AmountValueBuy>
                </WrapperContent>
              </ContentTransaction>
              <ContentTransaction>
                <WrapperContent>
                  <StatusTransaction success={isTransferSuccess(item)}>
                    {item.bridgeStatus === BridgeStatus.TRANSFER_UNKNOWN
                      ? `Initializing`
                      : item.bridgeStatus === BridgeStatus.TRANSFER_REFUND_TO_BE_CONFIRMED &&
                        item.confirmedRefundStatus === RequestedRefundType.CONFIRMED
                      ? capitalizeFirstLetter(
                          BridgeStatus.TRANSFER_CONFIRMING_YOUR_REFUND.toLowerCase().split('_').join(' ')
                        )
                      : item.bridgeStatus === BridgeStatus.TRANSFER_TO_BE_REFUNDED &&
                        item.confirmedRefundStatus === RequestedRefundType.REQUESTED
                      ? capitalizeFirstLetter(
                          BridgeStatus.TRANSFER_REQUESTING_REFUND.toLowerCase().split('_').join(' ')
                        )
                      : item.bridgeStatus &&
                        capitalizeFirstLetter(item.bridgeStatus.toLowerCase().split('_').join(' '))}{' '}
                    {!isTransferSuccess(item) && <ArrowUpRight />}
                  </StatusTransaction>
                  <TimeTransaction>{moment(item.swapTime).format('MMMM DD/YYYY, h:mm a')}</TimeTransaction>

                  {confirmRefundContent(item)}
                  {requestRefundContent(item)}
                  {item.bridgeStatus && item.bridgeStatus === BridgeStatus.TRANSFER_REFUND_TO_BE_CONFIRMED && (
                    <ConfirmRefundModal
                      isOpen={confirmRefundModal}
                      onDismiss={() => setConfirmRefundModal(false)}
                      onConfirm={() => handleConfirmRefund(item)}
                      confirming={confirming.current}
                    />
                  )}
                  {item.bridgeStatus && item.bridgeStatus === BridgeStatus.TRANSFER_TO_BE_REFUNDED && (
                    <RequestRefundModal
                      isOpen={requestRefundModal}
                      onDismiss={() => setRequestRefundModal(false)}
                      onConfirm={() => handleRequestRefund(item)}
                      requesting={requesting.current}
                    />
                  )}
                  {/* {error === item.transferId && (
                    <Text fontSize={12} fontWeight={400} color={theme.red2}>
                      An error has occured, please try later
                    </Text>
                  )} */}
                </WrapperContent>
              </ContentTransaction>
            </ListTransaction>
            <TransactionDetail item={item} />
          </WrapperCrossChain>
        ))}
    </div>
  )
}
export default TransStatusCrossChain
