import useActiveWeb3 from 'hooks/blockchain/useActiveWeb3'
import useDebounce from 'hooks/environment/useDebounce'
import useIsWindowVisible from 'hooks/environment/useIsWindowVisible'
import { atom } from 'jotai'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { useCallback, useEffect, useState } from 'react'

function useBlock() {
  const { networkId, library } = useActiveWeb3()
  const windowVisible = useIsWindowVisible()
  const [state, setState] = useState<{ networkId?: number; block?: number }>({ networkId })

  const onBlock = useCallback(
    (block: number) => {
      setState((state) => {
        if (state.networkId === networkId) {
          if (typeof state.block !== 'number') return { networkId, block }
          return { networkId, block: Math.max(block, state.block) }
        }
        return state
      })
    },
    [networkId]
  )

  useEffect(() => {
    if (library && networkId && windowVisible) {
      // If networkId hasn't changed, don't clear the block. This prevents re-fetching still valid data.
      setState((state) => (state.networkId === networkId ? state : { networkId }))

      library
        .getBlockNumber()
        .then(onBlock)
        .catch((error) => {
          console.error(`Failed to get block number for networkId ${networkId}`, error)
        })

      library.on('block', onBlock)
      return () => {
        library.removeListener('block', onBlock)
      }
    }
    return undefined
  }, [networkId, library, onBlock, windowVisible])

  const debouncedBlock = useDebounce(state.block, 100)
  return state.block ? debouncedBlock : undefined
}

const blockAtom = atom<number | undefined>(undefined)

export function BlockUpdater() {
  const setBlock = useUpdateAtom(blockAtom)
  const block = useBlock()
  useEffect(() => {
    setBlock(block)
  }, [block, setBlock])
  return null
}

/** Requires that BlockUpdater be installed in the DOM tree. */
export default function useBlockNumber(): number | undefined {
  const { networkId } = useActiveWeb3()
  const block = useAtomValue(blockAtom)
  return networkId ? block : undefined
}

export function useFastForwardBlockNumber(): (block: number) => void {
  return useUpdateAtom(blockAtom)
}
