import { NETWORK_INFO } from 'constants/networks'
import { capitalizeFirstLetter } from 'hooks/swap/useGetTransactionTitleAndLabel'
import moment from 'moment'
import { TransactionStatus } from 'state/transactions/actions'
import { ReponseTransactionStatus } from 'state/transactionStatus/type'
import { ReponseTransactionStatusType } from 'state/transactionStatus/type'
import styled from 'styled-components/macro'

import { ReactComponent as SwapIcon } from '../../../assets/svg/tokentransaction.svg'
import { TransactionDetail, TransType } from '../ListAllTransaction'
export const ListTransaction = styled.div`
  display: flex;
  padding: 10px 20px 20px 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-right: 20px;
`
export const WrapperPagination = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  bottom: 0px;
`
export const ContentTransaction = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`
const Swap = styled(SwapIcon)`
  width: 15px;
  height: 15px;
  margin-left: -2rem;
  margin-right: -2rem;
`
export const LogoNetWork = styled.img`
  width: 31px;
  height: 31px;
`
export const WrapperContent = styled.div`
  margin-left: 8px;
`
export const NetworkName = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.text6};
  margin-top: 15px;
  padding-bottom: 5px;
`
export const StatusTransaction = styled.p<{ success: boolean }>`
  color: ${({ theme, success }) => (success ? theme.green5 : theme.red4)};
  font-size: 12px;
  text-align: right;
  padding-bottom: 5px;
`
export const TimeTransaction = styled.p`
  color: ${({ theme }) => theme.timetransaction};
  margin-top: -15px;
  font-size: 12px;
  text-align: right;
`
export const TransactionDetails = styled.div`
  width: 580px;
  padding: 11px 20px 25px 20px;
`
export const ContentTransactionDetails = styled.div`
  display: flex;
  justify-content: space-between;
`
export const ColumnLeft = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 20px;
`
export const ColumnRight = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
`
export const InfoTransaction = styled.p`
  color: ${({ theme }) => theme.text2};
  font-size: 12px;
  font-weight: 400;
`
export const InfoValue = styled.p`
  color: ${({ theme }) => theme.text6};
  font-size: 12px;
  font-weight: 400;
`
export const WrapTransaction = styled.div`
  height: 550px;
  width: 587px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 24px;
  padding-bottom: 5px;
`
export const WrapperSwap = styled.div<{ open?: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ open }) => (open ? '245px' : '84px')};
  justify-content: space-between;
  align-items: center;
  width: 587px;
  border-radius: 12px;
  border: ${({ open }) => (open ? '1px solid rgba(205, 208, 215, 0.4)' : 'none')};
  box-shadow: 10px 24px 54px rgba(0, 0, 0, 0.06), 0px 0px 5px rgba(7, 44, 33, 0.05);
  background: ${({ theme }) => theme.bg0};
  transition: 0.5s;
  overflow: hidden;
`
export const TitleTradingDetail = styled.p`
  color: ${({ theme }) => theme.green4};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: -5px;
`
export const ValueAmountSell = styled.div`
  color: ${({ theme }) => theme.red4};
`
export const ValueAmountBuy = styled.div`
  color: ${({ theme }) => theme.green5};
`
export const ValueTransaction = styled.p`
  font-size: 12px;
  font-weight: 400;
  display: flex;
  width: 287px;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.green5};
  margin-top: -17px;
`
export const Divide = styled.div`
  background-color: ${({ theme }) => theme.border1};
  height: 1px;
`
export const WrapIconLoading = styled.div`
  margin-top: 35%;
  margin-left: 50%;
`
export const WrapNotify = styled.div`
  margin-top: 35%;
  margin-left: 36%;
  font-size: 16px;
  font-weight: 600;
`

const TransStatusSwap = ({
  transactions,
  handleOnClick,
  clickedIndex,
  setPage,
}: {
  transactions?: ReponseTransactionStatus
  handleOnClick?: any
  clickedIndex?: any
  setPage?: any
}) => {
  // const { logoUrl } = NETWORK_INFO[item.networkSwapId]
  return (
    <div>
      {transactions?.result.data
        .filter((item: ReponseTransactionStatusType) => item.type === TransType.SWAP)
        .map((item: ReponseTransactionStatusType, key: any) => (
          <WrapperSwap
            onClick={handleOnClick(item.transactionHash)}
            key={item.transactionHash}
            open={clickedIndex[item.transactionHash]}
          >
            <ListTransaction>
              <ContentTransaction>
                <LogoNetWork src={NETWORK_INFO[item.networkSwapId].logoUrl} />
                <WrapperContent>
                  <NetworkName>{item.networkSwap}</NetworkName>
                  <ValueTransaction>
                    <ValueAmountSell>
                      -{Number(item.amountSwap / Math.pow(10, item.tokenSwapDecimals)).toFixed(4)}{' '}
                      {item.tokenSwapSymbol}
                    </ValueAmountSell>
                    <Swap />
                    <ValueAmountBuy>
                      +{Number(item.amountReceived / Math.pow(10, item.tokenReceivedDecimals)).toFixed(4)}{' '}
                      {item.tokenReceivedSymbol}
                    </ValueAmountBuy>
                  </ValueTransaction>
                </WrapperContent>
              </ContentTransaction>
              <ContentTransaction>
                <WrapperContent>
                  <StatusTransaction success={item.status === TransactionStatus.SUCCESS}>
                    {capitalizeFirstLetter(item.status)}
                    {/* <ArrowUpRight /> */}
                  </StatusTransaction>
                  <TimeTransaction>{moment(item.swapTime).format('MMMM DD/YYYY, h:mm a')}</TimeTransaction>
                </WrapperContent>
              </ContentTransaction>
            </ListTransaction>
            <TransactionDetail item={item} />
          </WrapperSwap>
        ))}
    </div>
  )
}
export default TransStatusSwap
