export enum RequestedRefundType {
  REQUESTED = 'requested',
  CONFIRMED = 'confirmed',
}
const confirmedRefund = async ({
  transferId,
  confirmedRefundStatus,
}: {
  transferId: string
  confirmedRefundStatus: RequestedRefundType
}) => {
  const data = {
    transferID: transferId,
    confirmedRefundStatus,
  }

  const accessToken = localStorage.getItem('accessToken')
  const result = await fetch(`${process.env.REACT_APP_BACKEND_URL}/transaction/confirmed-refund`, {
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    method: 'PATCH',
  })
  const rs = await result.json()

  return rs
}
export default confirmedRefund
