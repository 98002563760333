/* eslint-disable @typescript-eslint/no-use-before-define */
import type { AriaListBoxOptions } from '@react-aria/listbox'
import type { Node } from '@react-types/shared'
import * as React from 'react'
import { useListBox, useListBoxSection, useOption } from 'react-aria'
import type { ListState } from 'react-stately'
import styled from 'styled-components/macro'

const StyledListGroup = styled.ul`
  max-height: 320px;
  width: 628px;
  overflow: auto;
  padding: 5px 0;
  border: 1 solid ${({ theme }) => theme.bg8};
`

const OptionItem = styled.li`
  padding: 4px 20px;
  border-radius: 8px;
  height: 56px;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const FocusedOptionItem = styled(OptionItem)`
  background-color: ${({ theme }) => theme.green2};
`

interface ListBoxProps extends AriaListBoxOptions<unknown> {
  listBoxRef?: React.RefObject<HTMLUListElement>
  state: ListState<unknown>
}

interface SectionProps {
  section: Node<unknown>
  state: ListState<unknown>
}

interface OptionProps {
  item: Node<unknown>
  state: ListState<unknown>
}

export function ListBox(props: ListBoxProps) {
  const ref = React.useRef<HTMLUListElement>(null)
  const { listBoxRef = ref, state } = props
  const { listBoxProps } = useListBox(props, state, listBoxRef)

  return (
    <StyledListGroup {...listBoxProps} ref={listBoxRef}>
      {[...state.collection].map((item) =>
        item.type === 'section' ? (
          <ListBoxSection key={item.key} section={item} state={state} />
        ) : (
          <Option key={item.key} item={item} state={state} />
        )
      )}
    </StyledListGroup>
  )
}

function ListBoxSection({ section, state }: SectionProps) {
  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: 'selection',
    'aria-label': 'selection',
  })

  return (
    <>
      <li {...itemProps} className="pt-2">
        {section.rendered && <span {...headingProps}>{section.rendered}</span>}
        <ul {...groupProps}>
          {[...section.childNodes].map((node) => (
            <Option key={node.key} item={node} state={state} />
          ))}
        </ul>
      </li>
    </>
  )
}

function Option({ item, state }: OptionProps) {
  const ref = React.useRef<HTMLLIElement>(null)
  const { optionProps, isFocused } = useOption(
    {
      key: item.key,
    },
    state,
    ref
  )

  return isFocused ? (
    <FocusedOptionItem {...optionProps} ref={ref}>
      {item.rendered}
    </FocusedOptionItem>
  ) : (
    <OptionItem {...optionProps} ref={ref}>
      {item.rendered}
    </OptionItem>
  )
}
