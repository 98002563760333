import { Trans } from '@lingui/macro'
import Loader from 'components/base/Loader'
import Modal from 'components/base/Modal'
import { useContext } from 'react'
// import { ClockLoader } from 'react-spinners'
// import Modal from 'components/base/Modal'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components/macro'
import { CloseIcon } from 'theme'

import { ReactComponent as Refunding } from '../../../../assets/svg/refund.svg'
import { ButtonPrimary } from '../../Button'
import { AutoColumn } from '../../Column'
import { RowBetween } from '../../Row'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.bg0};
  width: 480px;
  height: 480px;
  z-index: 150;
  top: 10rem;
  position: absolute;
  border-radius: 16px;
  box-shadow: 1px 1px 10px rgba(10, 4, 39, 0.05);
`

const Section = styled(AutoColumn)<{ inline?: boolean }>`
  padding: ${({ inline }) => (inline ? '0' : '0')};
`
const WrapCloseIcon = styled.div`
  margin-top: 20px;
  margin-right: 20px;
`
const DescriptionRefund = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
`

const ConfirmRefundModal = ({
  isOpen,
  onDismiss,
  onConfirm,
  confirming,
}: {
  isOpen: any
  onDismiss: any
  onConfirm: any
  confirming?: boolean
}) => {
  const theme = useContext(ThemeContext)
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxWidth={480}>
      <Wrapper>
        <Section inline={true}>
          <RowBetween>
            <div />
            <WrapCloseIcon>
              <CloseIcon onClick={onDismiss} />
            </WrapCloseIcon>
          </RowBetween>
          <AutoColumn justify={'center'} gap="64px" style={{ padding: '0 24px 24px' }}>
            <Text fontWeight={500} fontSize={20} lineHeight={'28px'} textAlign="center">
              <Trans>Refunding Transaction</Trans>
              <DescriptionRefund>This transaction will be refunded to your wallet</DescriptionRefund>
            </Text>
            <Refunding />
            <ButtonPrimary
              mt="12px"
              padding="6px 12px"
              width="100%"
              textAlign={'center'}
              marginBottom="0"
              onClick={() => {
                onConfirm()
                onDismiss()
              }}
              disabled={confirming}
            >
              {confirming ? <Loader size="20px" stroke={theme.green1} style={{ marginRight: '5px' }} /> : ''}
              <Text fontSize={20} fontWeight={500}>
                <Trans>Confirm</Trans>
              </Text>
            </ButtonPrimary>
          </AutoColumn>
        </Section>
      </Wrapper>
    </Modal>
  )
}

export default ConfirmRefundModal
