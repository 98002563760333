import useScrollPosition from '@react-hook/window-scroll'
import TransactionStatusModal from 'components/base/TransactionStatusModal'
import { NETWORK_INFO, SupportedNetworkId } from 'constants/networks'
import useActiveWeb3 from 'hooks/blockchain/useActiveWeb3'
import { useCallback, useState } from 'react'
import { Text } from 'rebass'
import { Field } from 'state/swap/actions'
import { useDerivedSwapInfo, useSwapActionHandlers } from 'state/swap/hooks'
import { useNativeCurrencyBalances } from 'state/wallet/hooks'
import styled from 'styled-components/macro'

import Web3Status, { PendingStatus } from '../../account/Web3Status'
import SearchBar from './Search'

export const HeaderFrame = styled.div<{ showBackground?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  top: 0;
  padding: 0 0.5rem 0 1rem;
  z-index: 21;
  position: relative;
  /* Background slide effect on scroll. */
  background-image: ${({ theme }) => `linear-gradient(to bottom, transparent 50%, ${theme.bg0} 50% )}}`};
  background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
  background-size: 100% 200%;
  box-shadow: 0 0 0 1px ${({ theme, showBackground }) => (showBackground ? theme.bg2 : 'transparent;')};
  transition: background-position 0.1s, box-shadow 0.1s;
  background-blend-mode: hard-light;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-columns: 48px 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding:  1rem;
    grid-template-columns: 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding:  1rem;
    grid-template-columns: 100px 1fr;
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-self: flex-end;
  justify-content: flex-end;
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 0.5em;
  }

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};
`

export const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg8 : theme.bg8)};
  border-radius: 4px;
  white-space: nowrap;
  border: ${({ theme }) => theme.border1};
  width: 100%;
  height: 40px;

  :focus {
    border: 1px solid blue;
  }
`

const BalanceText = styled(Text)`
  border: 1px solid #cdd0d7;
  border-radius: 4px;
  padding: 0.25rem 1rem 0.25rem 1rem;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`
const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`
const StyledAvatar = styled.img`
  height: inherit;
  width: inherit;
  border-radius: inherit;
`
const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
`

export default function Header() {
  const { account, networkId } = useActiveWeb3()

  const { onCurrencySelection } = useSwapActionHandlers()
  const { currencies } = useDerivedSwapInfo()
  const userEthBalance = useNativeCurrencyBalances(account ? [account] : [])?.[account ?? '']
  const [showSearchResult, setShowSearchResult] = useState<boolean>(false)
  const handleSearchToken = useCallback(() => {
    setShowSearchResult(!showSearchResult)
  }, [showSearchResult])
  const handleInputSelect = useCallback(
    (inputCurrency) => {
      // setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.OUTPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const scrollY = useScrollPosition()

  const {
    nativeCurrency: { symbol: nativeCurrencySymbol },
  } = NETWORK_INFO[networkId ? networkId : SupportedNetworkId.MAINNET]

  return (
    <HeaderFrame>
      <SearchBar
        onCurrencySelect={handleInputSelect}
        selectedCurrency={currencies[Field.OUTPUT]}
        otherSelectedCurrency={currencies[Field.INPUT]}
        showCurrencyAmount={true}
        disableNonToken={false}
        onDismiss={handleSearchToken}
      />
      <HeaderControls>
        <HeaderElement>
          <AccountElement active={!!account}>
            <PendingStatus />
            <TransactionStatusModal />
            {account && userEthBalance ? (
              <BalanceText
                style={{ flexShrink: 0, userSelect: 'none' }}
                fontWeight={400}
                fontSize={12}
                mr="1.2rem"
                ml="1rem"
              >
                {userEthBalance?.toSignificant(3)} {nativeCurrencySymbol}
              </BalanceText>
            ) : null}
            <Web3Status />
          </AccountElement>
        </HeaderElement>
      </HeaderControls>
    </HeaderFrame>
  )
}
