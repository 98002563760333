import React from 'react'
import styled from 'styled-components/macro'
const Tab = styled.button<{ selected?: boolean }>`
  width: 140px;
  border: none;
  font-weight: 600;
  padding: 4px 16px;
  color: ${({ theme, selected }) => (selected ? theme.text5 : theme.colorTab)};
  background-color: ${({ theme, selected }) => (selected ? theme.green4 : theme.timelineBtn)};
  font-size: 14px;
  transition: 0.5s;
  border-radius: ${({ selected }) => (selected ? '8px' : 'none')};
`
type TabTitleProps = {
  title: string
  index: number
  setSelectedTab: (index: number) => void
  selected: boolean
}

const TabTitle = ({ title, setSelectedTab, index, selected }: TabTitleProps) => {
  return (
    <Tab selected={selected} onClick={() => setSelectedTab(index)}>
      {title}
    </Tab>
  )
}

export default TabTitle
