import { createSlice } from '@reduxjs/toolkit'

import { TItemToken } from './slice'

export interface IState {
  tokenFavourites: TItemToken[]
  tokenWhatsHot: TItemToken[]
}

const initialState: IState = {
  tokenFavourites: [],
  tokenWhatsHot: [],
}

const tokens = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    updateTokenFavourites(state: IState, action) {
      if (!action.payload) {
        return
      }

      state.tokenFavourites = action.payload

      return state
    },
  },
})

export const { updateTokenFavourites } = tokens.actions
export default tokens.reducer
