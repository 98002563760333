import { Currency } from '@uniswap/sdk-core'
import { CheckTransactionType } from 'state/transactions/actions'

const getTransactionType = (inputCurrency?: Currency | null, outputCurrency?: Currency | null) => {
  if (!inputCurrency || !outputCurrency) return CheckTransactionType.TRADE
  if (inputCurrency.chainId === outputCurrency.chainId) {
    return CheckTransactionType.SWAP
  }
  if (inputCurrency.chainId !== outputCurrency.chainId && inputCurrency.symbol === outputCurrency.symbol) {
    return CheckTransactionType.BRIDGE
  }
  if (inputCurrency.chainId !== outputCurrency.chainId && inputCurrency.symbol !== outputCurrency.symbol) {
    return CheckTransactionType.CROSS_CHAIN
  }
  return CheckTransactionType.TRADE
}
export default getTransactionType
