import { BigNumber } from '@ethersproject/bignumber'
import { hexStripZeros } from '@ethersproject/bytes'
import { Web3Provider } from '@ethersproject/providers'
import { NETWORK_INFO, SupportedNetworkId } from 'constants/networks'
import { INFURA_NETWORK_URLS } from 'constants/networks'

interface SwitchNetworkArguments {
  library: Web3Provider
  networkId: SupportedNetworkId
}

function getRpcUrls(networkId: SupportedNetworkId): [string] {
  switch (networkId) {
    case SupportedNetworkId.MAINNET:
    case SupportedNetworkId.RINKEBY:
    case SupportedNetworkId.ROPSTEN:
    case SupportedNetworkId.GOERLI:
      return [INFURA_NETWORK_URLS[networkId]]
    case SupportedNetworkId.POLYGON:
      return ['https://polygon-rpc.com/']
    case SupportedNetworkId.POLYGON_MUMBAI:
      return ['https://rpc-endpoints.superfluid.dev/mumbai']
    default:
  }
  // Our API-keyed URLs will fail security checks when used with external wallets.
  throw new Error('RPC URLs must use public endpoints')
}

// provider.request returns Promise<any>, but wallet_switchEthereumChain must return null or throw
// see https://github.com/rekmarks/EIPs/blob/3326-create/EIPS/eip-3326.md for more info on wallet_switchEthereumChain
export async function switchToNetwork({ library, networkId }: SwitchNetworkArguments): Promise<null | void> {
  if (!library?.provider?.request) {
    return
  }
  const formattedChainId = hexStripZeros(BigNumber.from(networkId).toHexString())
  try {
    await library.provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: formattedChainId }],
    })
  } catch (error) {
    // 4902 is the error code for attempting to switch to an unrecognized networkId
    if (error.code === 4902) {
      const info = NETWORK_INFO[networkId]

      await library.provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: formattedChainId,
            chainName: info?.label,
            rpcUrls: getRpcUrls(networkId),
            nativeCurrency: info?.nativeCurrency,
            blockExplorerUrls: [info.explorer],
          },
        ],
      })
      // metamask (only known implementer) automatically switches after a network is added
      // the second call is done here because that behavior is not a part of the spec and cannot be relied upon in the future
      // metamask's behavior when switching to the current network is just to return null (a no-op)
      try {
        await library.provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: formattedChainId }],
        })
      } catch (error) {
        console.debug('Added network but could not switch chains', error)
      }
    } else {
      throw error
    }
  }
}
