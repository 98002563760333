import Web3 from 'web3'

export default class EventLogger {
  web3: Web3

  constructor() {
    this.web3 = new Web3((window as any).ethereum)
  }

  async sendAddress(publicAddress: string): Promise<{ publicAddress: string; nonce: string }> {
    const result = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/nonce/${publicAddress}`)
    return result.json()
  }

  async signMessage({ publicAddress, nonce }: { publicAddress: string; nonce: string }) {
    try {
      const signature = await this!.web3!.eth.personal.sign(
        `I am signing my one-time nonce: ${nonce}`,
        publicAddress,
        '' // MetaMask will ignore the password argument here
      )

      return { publicAddress, signature }
    } catch (err) {
      throw new Error('You need to sign the message to be able to log in.')
    }
  }

  async authenticate({ publicAddress, signature }: { publicAddress: string; signature: string }) {
    const result = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/verify-signature`, {
      body: JSON.stringify({ public_address: publicAddress, signature }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
    return result.json()
  }
}
