export const updateAppVersion = () => {
  const APP_VERSION = process.env.REACT_APP_CURRENT_GIT_SHA

  if (!APP_VERSION) {
    return false
  }

  if (
    typeof localStorage.APP_VERSION === 'undefined' ||
    localStorage.APP_VERSION === null ||
    localStorage.APP_VERSION !== APP_VERSION
  ) {
    console.log('Int3face app version ', APP_VERSION)
    localStorage.clear()
    clearCache()
    localStorage.setItem('APP_VERSION', APP_VERSION)
    return true
  }

  return false
}

const clearCache = () => {
  if (caches) {
    console.log('Deleting cache')

    caches.keys().then((names) => {
      names.forEach((name) => {
        console.log('Deleting cache: ', name)

        caches.delete(name)
      })
    })
  }
}
