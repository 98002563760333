import { Trans } from '@lingui/macro'
import { SDN_OFAC_ADDRESSES } from 'constants/blockedAddresses'
import useActiveWeb3 from 'hooks/blockchain/useActiveWeb3'
import { ReactNode, useMemo } from 'react'

/**
 * Function to check if the connected addresses have been blocked.
 * - TODO add proper lists and check if we need SDN_OFAC or others
 * @author   leon@dandelionlabs.io
 * @param    {ReactNode} children children
 * @return   {ReactNode}          children or div with blocked TODO redirect to blocked page
 */
export default function AddressCheck({ children }: { children: ReactNode }) {
  const { account } = useActiveWeb3()
  const blocked: boolean = useMemo(() => Boolean(account && SDN_OFAC_ADDRESSES.indexOf(account) !== -1), [account])
  if (blocked) {
    return (
      <div>
        <Trans>Blocked address</Trans>
      </div>
    )
  }
  return <>{children}</>
}
