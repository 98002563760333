const getTransferStatus = async ({ transferId, networkId }: { transferId: string; networkId: number }) => {
  const accessToken = localStorage.getItem('accessToken')
  const backend = process.env.REACT_APP_BACKEND_URL

  //process.env.REACT_APP_BACKEND_URL
  const result = await fetch(`${backend}/transaction/${transferId}/status`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    method: 'GET',
  })
  const rs = await result.json()

  return rs
}
export default getTransferStatus
