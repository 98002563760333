import { Trans } from '@lingui/macro'
import { ReactComponent as Close } from 'assets/images/x.svg'
import { useModalOpen, useToggleTransactionStatus } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import styled from 'styled-components/macro'

import ListAllTransaction from '../ListAllTransaction'
import ListCrossChainTransaction from '../ListCrossChainTransaction'
import ListSwapTransaction from '../ListSwapTransaction'
// import { AutoColumn } from '../Column'
import Modal from '../Modal'
import Tab from '../Tab/Tab'
import Tabs from '../Tab/Tabs'
const WrapperTransactionStatus = styled.div`
  padding: 16px;
  width: 100%;
  position: relative;
`

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  width: 587px;
  font-size: 20px;
  color: ${({ theme }) => theme.text6};
`
const HoverText = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`
const CloseIcon = styled.div`
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`
const DesTransaction = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
`
export default function TransactionStatusModal() {
  const transactionModalOpen = useModalOpen(ApplicationModal.TRANSACTION_STATUS)
  const toggleTransactionModal = useToggleTransactionStatus()

  function getTransactionStatusContent() {
    return (
      <>
        <WrapperTransactionStatus>
          <HeaderRow>
            <HoverText>
              <Trans>Transaction Status</Trans>
            </HoverText>
            <CloseIcon onClick={toggleTransactionModal}>
              <CloseColor />
            </CloseIcon>
          </HeaderRow>
          <DesTransaction>Lorem ipsum dolor sit amet consectetur adipisicing elit.</DesTransaction>
          <Tabs>
            <Tab title="All">
              <ListAllTransaction />
            </Tab>
            <Tab title="Cross-chain">
              <ListCrossChainTransaction />
            </Tab>
            <Tab title="Swapping">
              <ListSwapTransaction />
            </Tab>
          </Tabs>
        </WrapperTransactionStatus>
      </>
    )
  }

  return (
    <Modal isOpen={transactionModalOpen} onDismiss={toggleTransactionModal} minHeight={789} maxHeight={789}>
      <div>{getTransactionStatusContent()}</div>
    </Modal>
  )
}
