import { ChainId } from '@uniswap/smart-order-router'
import ms from 'ms.macro'

import ethereumLogoUrl from '../assets/images/ethereum-logo.png'
import polygonMaticLogo from '../assets/images/polygon.png'

export enum SupportedNetworkId {
  MAINNET = ChainId.MAINNET,
  ROPSTEN = ChainId.ROPSTEN,
  RINKEBY = ChainId.RINKEBY,
  GOERLI = ChainId.GÖRLI,

  POLYGON = ChainId.POLYGON,
  POLYGON_MUMBAI = ChainId.POLYGON_MUMBAI,
}

export const NETWORK_IDS_TO_NAMES = {
  [SupportedNetworkId.MAINNET]: 'mainnet',
  [SupportedNetworkId.ROPSTEN]: 'ropsten',
  [SupportedNetworkId.RINKEBY]: 'rinkeby',
  [SupportedNetworkId.GOERLI]: 'goerli',
  [SupportedNetworkId.POLYGON]: 'polygon',
  [SupportedNetworkId.POLYGON_MUMBAI]: 'polygon_mumbai',
}

export const ALL_SUPPORTED_NETWORK_IDS: SupportedNetworkId[] = Object.values(SupportedNetworkId).filter(
  (id) => typeof id === 'number'
) as SupportedNetworkId[]

export const SUPPORTED_GAS_ESTIMATE_NETWORK_IDS = [SupportedNetworkId.MAINNET, SupportedNetworkId.POLYGON]

export const EVM_NETWORK_IDS = [
  SupportedNetworkId.MAINNET,
  SupportedNetworkId.ROPSTEN,
  SupportedNetworkId.RINKEBY,
  SupportedNetworkId.GOERLI,
  SupportedNetworkId.POLYGON,
  SupportedNetworkId.POLYGON_MUMBAI,
] as const

export type SupportedEVMNetworkId = typeof EVM_NETWORK_IDS[number]

export interface BaseNetworkInfo {
  readonly blockWaitMsBeforeWarning?: number
  readonly docs: string
  readonly explorer: string
  readonly infoLink: string
  readonly logoUrl: string
  readonly label: string
  readonly chainId: SupportedNetworkId
  readonly helpCenterUrl?: string
  readonly nativeCurrency: {
    name: string // e.g. 'Goerli ETH',
    symbol: string // e.g. 'gorETH',
    decimals: number // e.g. 18,
  }
  readonly bridgeSmartContract: string
}

export type NetworkInfoMap = { readonly [networkId: number]: BaseNetworkInfo } & {
  readonly [networkId in SupportedEVMNetworkId]: BaseNetworkInfo
}
export const SWAP_SUPPORT_NETWORK: NetworkInfoMap = {
  [SupportedNetworkId.MAINNET]: {
    docs: 'https://docs.int3face.io/',
    explorer: 'https://etherscan.io/',
    infoLink: 'https://info.int3face.io/#/',
    label: 'Ethereum',
    chainId: SupportedNetworkId.MAINNET,
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    bridgeSmartContract: '0x5427FEFA711Eff984124bFBB1AB6fbf5E3DA1820',
  },
  [SupportedNetworkId.ROPSTEN]: {
    docs: 'https://docs.int3face.io/',
    explorer: 'https://ropsten.etherscan.io/',
    infoLink: 'https://info.int3face.io/#/',
    label: 'Ropsten',
    chainId: SupportedNetworkId.ROPSTEN,
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Ropsten Ether', symbol: 'ropETH', decimals: 18 },
    bridgeSmartContract: '',
  },
  [SupportedNetworkId.GOERLI]: {
    docs: 'https://docs.int3face.io/',
    explorer: 'https://goerli.etherscan.io/',
    infoLink: 'https://info.int3face.io/#/',
    label: 'Görli',
    chainId: SupportedNetworkId.GOERLI,
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Görli Ether', symbol: 'görETH', decimals: 18 },
    bridgeSmartContract: '0x358234B325EF9eA8115291A8b81b7d33A2Fa762D',
  },
  [SupportedNetworkId.POLYGON]: {
    blockWaitMsBeforeWarning: ms`10m`,
    docs: 'https://polygon.io/',
    explorer: 'https://polygonscan.com/',
    infoLink: 'https://info.int3face.io/#/polygon/',
    label: 'Polygon',
    chainId: SupportedNetworkId.POLYGON,
    logoUrl: polygonMaticLogo,
    nativeCurrency: { name: 'Polygon Matic', symbol: 'MATIC', decimals: 18 },
    bridgeSmartContract: '0x88DCDC47D2f83a99CF0000FDF667A468bB958a78',
  },
  [SupportedNetworkId.POLYGON_MUMBAI]: {
    blockWaitMsBeforeWarning: ms`10m`,
    docs: 'https://polygon.io/',
    explorer: 'https://mumbai.polygonscan.com/',
    infoLink: 'https://info.int3face.io/#/polygon/',
    label: 'Polygon Mumbai',
    chainId: SupportedNetworkId.POLYGON_MUMBAI,
    logoUrl: polygonMaticLogo,
    nativeCurrency: { name: 'Polygon Mumbai Matic', symbol: 'mMATIC', decimals: 18 },
    bridgeSmartContract: '0x841ce48F9446C8E281D3F1444cB859b4A6D0738C',
  },
}

export const NETWORK_INFO: NetworkInfoMap = {
  [SupportedNetworkId.MAINNET]: {
    docs: 'https://docs.int3face.io/',
    explorer: 'https://etherscan.io/',
    infoLink: 'https://info.int3face.io/#/',
    label: 'Ethereum',
    chainId: SupportedNetworkId.MAINNET,
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    bridgeSmartContract: '0x5427FEFA711Eff984124bFBB1AB6fbf5E3DA1820',
  },
  [SupportedNetworkId.RINKEBY]: {
    docs: 'https://docs.int3face.io/',
    explorer: 'https://rinkeby.etherscan.io/',
    infoLink: 'https://info.int3face.io/#/',
    label: 'Rinkeby',
    chainId: SupportedNetworkId.RINKEBY,
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Rinkeby Ether', symbol: 'rETH', decimals: 18 },
    bridgeSmartContract: '',
  },
  [SupportedNetworkId.ROPSTEN]: {
    docs: 'https://docs.int3face.io/',
    explorer: 'https://ropsten.etherscan.io/',
    infoLink: 'https://info.int3face.io/#/',
    label: 'Ropsten',
    chainId: SupportedNetworkId.ROPSTEN,
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Ropsten Ether', symbol: 'ropETH', decimals: 18 },
    bridgeSmartContract: '',
  },
  [SupportedNetworkId.GOERLI]: {
    docs: 'https://docs.int3face.io/',
    explorer: 'https://goerli.etherscan.io/',
    infoLink: 'https://info.int3face.io/#/',
    label: 'Görli',
    chainId: SupportedNetworkId.GOERLI,
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Görli Ether', symbol: 'görETH', decimals: 18 },
    bridgeSmartContract: '0x358234B325EF9eA8115291A8b81b7d33A2Fa762D',
  },
  [SupportedNetworkId.POLYGON]: {
    blockWaitMsBeforeWarning: ms`10m`,
    docs: 'https://polygon.io/',
    explorer: 'https://polygonscan.com/',
    infoLink: 'https://info.int3face.io/#/polygon/',
    label: 'Polygon',
    chainId: SupportedNetworkId.POLYGON,
    logoUrl: polygonMaticLogo,
    nativeCurrency: { name: 'Polygon Matic', symbol: 'MATIC', decimals: 18 },
    bridgeSmartContract: '0x88DCDC47D2f83a99CF0000FDF667A468bB958a78',
  },
  [SupportedNetworkId.POLYGON_MUMBAI]: {
    blockWaitMsBeforeWarning: ms`10m`,
    docs: 'https://polygon.io/',
    explorer: 'https://mumbai.polygonscan.com/',
    infoLink: 'https://info.int3face.io/#/polygon/',
    label: 'Polygon Mumbai',
    chainId: SupportedNetworkId.POLYGON_MUMBAI,
    logoUrl: polygonMaticLogo,
    nativeCurrency: { name: 'Polygon Mumbai Matic', symbol: 'mMATIC', decimals: 18 },
    bridgeSmartContract: '0x841ce48F9446C8E281D3F1444cB859b4A6D0738C',
  },
}

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY
if (typeof INFURA_KEY === 'undefined') {
  throw new Error(`REACT_APP_INFURA_KEY must be a defined environment variable`)
}

/**
 * These are the network URLs used by the interface when there is not another available source of chain data
 */
export const INFURA_NETWORK_URLS: { [key in SupportedNetworkId]: string } = {
  [SupportedNetworkId.MAINNET]: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
  [SupportedNetworkId.RINKEBY]: `https://rinkeby.infura.io/v3/${INFURA_KEY}`,
  [SupportedNetworkId.ROPSTEN]: `https://ropsten.infura.io/v3/${INFURA_KEY}`,
  [SupportedNetworkId.GOERLI]: `https://goerli.infura.io/v3/${INFURA_KEY}`,
  [SupportedNetworkId.POLYGON]: `https://polygon-mainnet.infura.io/v3/${INFURA_KEY}`,
  [SupportedNetworkId.POLYGON_MUMBAI]: `https://polygon-mumbai.infura.io/v3/${INFURA_KEY}`,
}

export interface NetworkSpecificConfigs {
  rpcUrl: string
  networkId: number
  chainId: number
}

export const ROPSTEN_CONFIGS: NetworkSpecificConfigs = {
  rpcUrl: INFURA_NETWORK_URLS[SupportedNetworkId.ROPSTEN],
  networkId: SupportedNetworkId.ROPSTEN,
  chainId: SupportedNetworkId.ROPSTEN,
}
